import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import coat from './assets/coat.png';
import {Link} from "react-router-dom";
import {LANGUAGES} from "./constants";
import {useTranslation} from "react-i18next";
import {Button} from "react-bootstrap";
import React from "react";

function CustomNavBar() {
    const { i18n, t } = useTranslation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng).then(() => localStorage.setItem("lang", lng));
    };

    return (
        <Navbar expand="lg" fixed={'top'} className="bg-body-tertiary border-bottom">
            <Container>
                <Link to="/" className={'navbar-brand'}> <img src={coat} alt="Coat" style={{height:"50px"}}/> <span className={'ms-3'}>{t('government')}</span></Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                    </Nav>
                    <Nav className={'align-items-center'}>
                        <Link to="/" className={'nav-link'}>{t('home')}</Link>
                        <Link to="/legal" className={'nav-link'}>{t('legal_notices0')}</Link>
                        <div className={'me-lg-5'}>
                            <div className={'me-lg-5'}>
                                <Link to="/contact" className={'nav-link me-lg-5'}>{t('contact_us')}</Link>
                            </div>
                        </div>
                        <div>
                            <NavDropdown title={t('languages')} id="basic-nav-dropdown" className={''}>
                                {LANGUAGES.map(value => <NavDropdown.Item key={value.code} onClick={() => {
                                    changeLanguage(value.code);
                                }} to={`#`} tag={Link}>
                                    {value.label}
                                </NavDropdown.Item>)}
                            </NavDropdown>
                        </div>
                        <Nav.Link href={process.env.REACT_APP_ADMIN_PORTAL_URL}>
                            <Button className={'rounded-pill btn-sm'} variant={'success'} style={{backgroundColor:"#63866F"}}>{t('login')}</Button>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default CustomNavBar;
