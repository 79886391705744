import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import coat from './assets/coat.png';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {LANGUAGES} from "./constants";
import {useTranslation} from "react-i18next";
import {Button} from "react-bootstrap";
import React from "react";

function CustomNavBar() {
    const { i18n, t } = useTranslation();
    const location = useLocation();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng).then(() => localStorage.setItem("lang", lng));
    };

    return (
        <Navbar expand="lg" fixed={'top'} className="bg-body-tertiary border-bottom">
            <Container>
                <Link to="/" className={'navbar-brand'}> <img src={coat} alt="Coat" style={{height:"50px"}}/> <span className={'ms-3 raleway-2'} style={{color:"#20603D",fontWeight:"bold"}}>{t('government')}</span></Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                    </Nav>
                    <Nav className={'align-items-center'}>
                        <Link to="/" title={"This website is comprised of laws of Rwanda, orders and regulations in force and not in force as well classified in the following ten domains: Fundamental laws, Human rights related laws, Diplomatic and consular laws, Criminal laws, Security laws, Judicial laws, administrative laws, Taxation laws, Civil laws, and Business laws. It also includes selected court decisions rendered by superior courts of Rwanda: Supreme Court, Court of Appeal, High Court and Commercial High Court."} className={`nav-link me-lg-4 ${location.pathname === '/' ? 'active' : ''}`}>{t('home')}</Link>
                        <Link to="/legal" title={'Rwanda Law Reform Commission (RLRC) governed by the Law no 023/2024 of 25/01/2024 has the mission of analyzing laws in force and providing technical advice on draft bills with regard to both substance and drafting.'} className={`nav-link me-lg-4 ${location.pathname.includes("legal") ? 'active' : ''}`}>{t('legal_notices0')}</Link>
                        <Link to="/contact" title={'Get in Touch, We are help for you! How can we help you?'} className={`nav-link me-lg-4 ${location.pathname.includes("contact") ? 'active' : ''}`}>{t('contact_us')}</Link>

                        <div className={'me-lg-4'}>
                            <NavDropdown title={t('languages')} id="basic-nav-dropdown" className={''}>
                                {LANGUAGES.map(value => <NavDropdown.Item key={value.code} onClick={() => {
                                    changeLanguage(value.code);
                                }} to={`#`} tag={Link}>
                                    {value.label}
                                </NavDropdown.Item>)}
                            </NavDropdown>
                        </div>
                        <Nav.Link href={process.env.REACT_APP_ADMIN_PORTAL_URL}>{t('login')}</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default CustomNavBar;
