import {Link} from "react-router-dom";
import {highlight, inArray} from "../store/helpers/common_functions";
import {Badge} from "react-bootstrap";
import DocumentItem from "./DocumentItem";

const DocumentSearchItem = ({item,search})=>{
    const source = item._source;
    const isDecision =  inArray(["2.1"],source.document_section);
    // const text = "KU WA 06/04/2017 LAW N0 013 BIS /2019 OF 30/06/2019 MODIFYING LAW N° 11/2017 OF LOI [...] KU WA 06/04/2017 LAW N0 013 BIS /2019 OF 30/06/2019 MODIFYING LAW N° 11/2017 OF LOI [...] reviewed Law n1Oo 11/2017 of 06/04/2017 establishing Rwanda Law Enforcement [...] bukurikira: Article 52 of Law n° 11/2017 of 06/04/2017 establishing Rwanda Law Enforcement Specialized [...] ESTABLISHING RWANDA RISHYIRAHO ISHURI RIKURU LAW ENFORCEMENT SPECIALISED RYIHARIYE RY’IGIHUGU";
    const text = item.highlight?.file_text?.join(" [.......] ") ?? "";
    return isDecision ? <Link to={`/view/doc/${source.document_id}/${source.file_id}`} className={'text-start border-bottom p-3 d-block list-item-hover text-decoration-none'}>
        <div className='d-flex align-items-center'>
            {/*<i className={'bi bi-file-pdf text-danger'} style={{fontSize:"25px",fontWeight:"bolder"}}></i>*/}
            <span className={'border rounded-pill p-2'}>
                <img alt={'image'} src={require('./../assets/case_law.png')} style={{width:"20px",height:"20px"}}/>
            </span>
            <span className={'mx-2'} style={{fontWeight:"bold",color:"#1aa1de"}}>{source.document_name}</span>
            <span className={'text-black-50'} style={{fontSize:"14px"}}> - {source.document_report_citation} - {source.document_date}</span>
        </div>
        <div className={'text-black'} style={{fontSize:"14px"}}><span>{source.document_collection_name} <b>-</b> </span> <span>{source.document_decisions_types_name} <b>-</b></span> <span>{source.document_decisions_status_name} <b>-</b></span> {source.document_case_no}</div>
         <div className={'mb-1'}>{source.document_keywords?.split(",")?.map(v=><Badge bg={'secondary'} className={'me-1'}>{v}</Badge>)}</div>
        <p className={'text-black-50 text-justify'} style={{fontSize:"14px",textAlign:"justify"}} dangerouslySetInnerHTML={{__html:text}}>
        </p>
    </Link> : <DocumentItem item={item} search={search}/>
}

export default DocumentSearchItem;
