import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

 const PaginationComponent = ({limit,active,total,onChange})=> {

    let items = [];
    const lmt = Math.ceil(total/limit);
    const newStart = Math.max(active - 5,1);
    const newEnd = Math.min(active + 5,lmt);
    for (let number = newStart; number <= newEnd; number++) {
        items.push(
            <Pagination.Item onClick={()=>onChange(number)} key={number} active={number === active}>
                {number}
            </Pagination.Item>,
        );
    }

    return (
        <div className={'d-flex justify-content-center mt-3'}>
            <Pagination>

                <Pagination.First  onClick={()=>onChange(0)} disabled={active <= 0}/>
                <Pagination.Prev  onClick={()=>onChange(active-1)} disabled={active <= 0}/>
                {(active-5)>=0 && <Pagination.Ellipsis disabled={true}/>}
                {items}
                {(active+5)<lmt && <Pagination.Ellipsis disabled={true}/>}
                <Pagination.Next onClick={()=>onChange(active+1)} disabled={active >= lmt}/>
                <Pagination.Last onClick={()=>onChange(lmt)} disabled={active >= lmt}/>
            </Pagination>
        </div>
    );

}

export default PaginationComponent;