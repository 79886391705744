import {Button, Card, FormGroup} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {mapStateToProps} from "../store/helpers/mapState";
import {mapDispatchToProps} from "../store/helpers/mapDispatch";
import {useRef, useState} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import LoadingSpinner from "../components/LoadingSpinner";
import {errorToast, successToast} from "../helpers/Notification";
import {getError, getSuccessMessage} from "../store/helpers/common_functions";

function ContactPage({SubmitGeneralForm}) {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [captcha,setCaptcha] = useState(null);
    const recaptchaRef = useRef(null);
    const form = useRef(null);
    function onChange(value) {
        console.log("Captcha value:", value);
        setCaptcha(value);
    }
    const submitForm = async (body)=>{
        setLoading(true);
        const resp = await SubmitGeneralForm("site/contact",body);
        if(resp.status){
            form?.current?.reset();
            recaptchaRef?.current?.reset();
            successToast(getSuccessMessage(resp));
        }else{
            errorToast(getError(resp));
        }
        setLoading(false);
    }

    return <div className={'row'}>
        <div className={'col-md-4 text-start'}>

            <h1>{t('in_touch')}</h1>
            <div><span>{t('help')}</span></div>

            <form action="" ref={form} onSubmit={e=>{
                e.preventDefault();
                const formData = new FormData(e.currentTarget);

                submitForm({
                    name:formData.get("names"),
                    email:formData.get("email"),
                    message:formData.get("message"),
                    captcha
                }).then();


            }} method={'POST'} className={'mt-5 position-relative'}>
                {loading && <div style={{
                    background:"rgba(255,255,255,0.5)",
                    zIndex:12
                }} className={'position-absolute top-0 left-0 w-100 h-100 d-flex justify-content-center align-items-center'}>
                    <LoadingSpinner/>
                </div>}
                <FormGroup className={'mb-3'}>
                    <input className={'form-control'} placeholder={t('names')} name={'names'} required={true}/>
                </FormGroup>
                <FormGroup className={'mb-3'}>
                    <input className={'form-control'} type={'email'} name={'email'} placeholder={t('email')} required={true}/>
                </FormGroup>
                <FormGroup className={'mb-3'}>
                    <textarea className={'form-control'} name={'message'} placeholder={t('message')} rows={7} required={true}></textarea>
                </FormGroup>
                <div className={'overflow-hidden mb-3'}>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6LeLwB0lAAAAAHjH_Ve0hjkissvnjPADgLa5JSkP"
                        onChange={onChange}
                    />
                </div>
                <Button variant={'success'} type={'submit'} style={{backgroundColor:"#63866F"}}>{t('send_now')}</Button>
            </form>
        </div>
        <div className={'col-md-8'}>
            <Card className={'border-0 shadow-sm'}>
                <div className={'card-body pt-5'}>
                    <img src={require('./../assets/contact_law.png')} alt=""/>
                    <div className={'d-flex mt-5 mx-5'}>
                        <div className={'flex-grow-1 text-start'}>
                            <b>{t('contact_judiciary')}</b>
                            <div className={'mt-2'} style={{fontSize:"22px"}}>
                                <i className={'bi bi-telephone-fill'} style={{color:"#63866F"}}></i>
                                <span className={'ms-3'}>3670/9040</span>
                            </div>
                            <div className={'mt-2'} style={{fontSize:"22px"}}>
                                <i className={'bi bi-envelope-fill'} style={{color:"#63866F"}}></i>
                                <span className={'ms-3'}>info@judiciary.gov.rw</span>
                            </div>
                        </div>
                        <div className={'flex-grow-1 text-start'}>
                            <b>{t('contact_legislation')}</b>
                            <div className={'mt-2'} style={{fontSize:"22px"}}>
                                <i className={'bi bi-telephone-fill'} style={{color:"#63866F"}}></i>
                                <span className={'ms-3'}>1910</span>
                            </div>
                            <div className={'mt-2'} style={{fontSize:"22px"}}>
                                <i className={'bi bi-envelope-fill'} style={{color:"#63866F"}}></i>
                                <span className={'ms-3'}>info@rlrc.gov.rw</span>
                            </div>
                        </div>
                    </div>
                    <div className={'mt-5 mb-5'}>
                        {t('follow_us')}
                        <div className={'d-flex justify-content-center mt-2'} style={{color:"#63866F"}}>
                            <a href="https://www.facebook.com/RwandaLawReformComission/" target={"_blank"} rel={"noreferrer"} className={'me-4'}>
                                <i className={'bi bi-facebook'} style={{fontSize:"30px",color:"#63866F"}}></i>
                            </a>
                            <a href="https://x.com/RwandaLawReform." target={"_blank"} rel={"noreferrer"} className={'me-4'}>
                                <i className={'bi bi-twitter-x'} style={{fontSize:"30px",color:"#63866F"}}></i>
                            </a>
                            <a href="https://www.youtube.com/@rwandalawreformcrlrc6944/videos" target={"_blank"} rel={"noreferrer"} className={'me-4'}>
                                <i className={'bi bi-youtube'} style={{fontSize:"30px",color:"#63866F"}}></i>
                            </a>
                            <a href="https://www.instagram.com/rwanda_lawreform/" target={"_blank"} rel={"noreferrer"} className={'me-4'}>
                                <i className={'bi bi-instagram'} style={{fontSize:"30px",color:"#63866F"}}></i>
                            </a>
                            {/*<a href="https://linkedin.com" className={'me-4'}>*/}
                            {/*    <i className={'bi bi-linkedin'} style={{fontSize:"30px"}}></i>*/}
                            {/*</a>*/}
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    </div>
}

export default connect(mapStateToProps,mapDispatchToProps)(ContactPage);