import {Link} from "react-router-dom";
import {highlight, inArray} from "../store/helpers/common_functions";

const DocumentItem = ({item,search})=>{
    const text = item.highlight?.file_text?.join(" [.......] ") ?? "";
    const source = item?._source;
    const isLegislation =  inArray(["1.1","1.2"],source.document_section);
    const isResource =  inArray(["2.2.1","2.2.2","2.2.3"],source.document_section);
    return <Link to={isLegislation ? `/view/toc/doc/${source?.document_id}/${source?.file_id}` : `/view/doc/${source?.document_id}/${source?.file_id}`} className={'text-start border-bottom p-3 d-block list-item-hover text-decoration-none'}>
        <div className={'d-flex align-items-center '}>
            <span className={'border rounded-pill p-2'}>
                <img alt={'logo'} src={require(`./../assets/${isLegislation ? 'legislation.png': 'case_law.png'}`)} style={{height:"20px",width:"20px"}}/>
            </span>
            <span className={'ms-2 flex-grow-1'} style={{fontWeight:"bold",color:"#1aa1de"}}>{item._source?.document_name}</span>
            <span style={{fontSize:"12px",color:"#333333"}}>{item._source?.document_date}</span>
        </div>
        {isResource &&     <div className={'text-black'} style={{fontSize:"14px"}}><span>{source.document_collection_name}</span> {source.document_volume_name !== null && <span><span><b>-</b> </span> <span>{source.document_volume_name}</span></span>}</div>}
        {search !== undefined && search !== null && <p className={'text-black-50 mt-2'} style={{fontSize: "14px",textAlign:"justify"}}
                                    dangerouslySetInnerHTML={{__html: highlight(search, text)}}></p>}
    </Link>
}

export default DocumentItem;
