import * as tocbot from 'tocbot';
import React, {useEffect, useState} from "react";
import content from '../json/content.json'
import {downloadAndSave, highlight, regexIndexOf} from "../store/helpers/common_functions";
import {useTranslation} from "react-i18next";
import {Button, Col, Row, Spinner, Tab, Tabs} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import DocumentView from "../pages/DocumentView";
import {connect} from "react-redux";
import {mapStateToProps} from "../store/helpers/mapState";
import {mapDispatchToProps} from "../store/helpers/mapDispatch";
import HtmlDocumentViewer from "./html_document_viewer";
import TextDocumentViewer from "./text_document_viewer";

const CaseNumberDocumentViewer = ({FetchDetails,fromJudge}) => {

    const [active, setActive] = useState(0);

    const [list, setList] = useState([]);
    const [path, setPath] = React.useState(null);
    const [pageNum, setPageNum] = React.useState(10);
    const [loading, setLoading] = React.useState(true);
    const [info,setInfo] = React.useState(null);
    const [references, setReferences] = React.useState([]);
    const [found, setFound] = useState(null);

    const {i18n} = useTranslation();
    const [iFileId, setIFileId] = useState(null);
    const [lng, setLng] = useState();

    const {caseNo} = useParams();

    const navigate = useNavigate();

    console.log(caseNo);

    function getRex(search) {
        search = search.replace(/[.*+?^${}()|[\]\\]/gi, '\\$&'); //https://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex

        return new RegExp(search, 'gi');
    }


    const loadDetails = async ()=>{
        setLoading(true);
        setInfo(null);
        setPath(null);
        setFound(null);
        const resp = await FetchDetails(`documents/reference?case_no=${encodeURIComponent(caseNo)}`);
        if(resp.status){
            console.log(resp.data);
            setInfo(resp.data);
        }
        setLoading(false);
    }


    const loadReferences = async ()=>{
        const resp = await FetchDetails(`documents/references/${iFileId}`);
        if(resp.status){
            setReferences(resp.data ?? []);
        }
    }

    useEffect(()=>{
        if(iFileId) {
            loadReferences().then();
            loadDocumentView().then();
        }
    },[iFileId]);



    const loadDocuments = async () => {

        setLoading(true);
        const resp = await FetchDetails(`documents-files/table?document=${info?.id}`);
        setLoading(false);
        if (resp.status) {
            const find = resp.data.find(v => v.language === i18n.language || v.languages === i18n.language || v.languages?.split(",")?.filter(v=>v.trim() === i18n.language)?.length>0);
            if (find) {
                setIFileId(find.id);
                // setReferences(find.references?.split(",") ?? []);
            } else {
                const find1 = resp.data.find(v => v.language === "un" || v.languages === "un");
                if (find1) {
                    // setReferences(find1.references?.split(",") ?? []);
                    setIFileId(find1.id);
                } else {
                    if(resp.data.length){
                        setFound(resp.data[0]);
                    }
                    setPath(null);
                }
            }
        }
    }
    const loadDocumentView = async () => {

        setLoading(true);
        const resp = await FetchDetails(`documents-files/${iFileId}`);
        setLoading(false);
        if (resp.status) {
                setPath(resp.data?.file_url);
                // setReferences(find.references?.split(",") ?? []);
        }
    }


    useEffect(() => {
        if (i18n.language && lng !== i18n.language && info?.id) {
            loadDocuments().then();
            setLng(i18n.language);
        }
    }, [i18n.language,info]);

    useEffect(()=>{
        if(caseNo){
            loadDetails().then();
        }
    },[caseNo]);

    useEffect(() => {
        if (active === 0) {
            tocbot.init({
                // Where to render the table of contents.
                tocSelector: '.script-toc',
                // Where to grab the headings to build the table of contents.
                contentSelector: '.toc-main',
                // Which headings to grab inside of the contentSelector element.
                headingSelector: 'h1, h2, h3',
                // For headings inside relative or absolute positioned containers within content.
                hasInnerContainers: true,
                // Main class to add to lists.
                linkClass: 'toc-link',
                // Class that gets added when a list should be collapsed.
                isCollapsedClass: 'is-collapsed',
                // Smooth scrolling enabled.
                scrollSmooth: true,
                // Smooth scroll duration.
                scrollSmoothDuration: 420,
                headingsOffset: 40,
                collapseDepth: 0,
            });
            document.body.onscroll = function () {
                const toc = document.querySelector(".js-toc-container")
                if (toc) {
                    const passedScroll = this.scrollY >= 150;
                    toc.style.position = passedScroll ? "fixed" : "absolute";
                    toc.style.top = passedScroll ? "55px" : "auto";
                }

            }
        }
    }, [active, i18n.language])





    return (
       loading ? <div className={'d-flex align-items-center justify-content-center'} style={{minHeight:"82vh"}}>
           <Spinner/>
       </div> : <div className="App" style={{marginTop: "60px"}}>



            {found !== null && <div style={{minHeight:"82vh"}} className={'d-flex align-items-center justify-content-center'}>
                <p>
                    This document is only available in <b>{found.language === 'rw' || found.languages === 'rw' ? 'Kinyarwanda' : found.language === 'fr' || found.languages === 'fr'  ? 'French' : 'English'}</b>
                    <div>Switch to the available language to view this document</div>
                    <div><Button className={'mt-2 bg-greener rounded-pill'} variant={'success'} onClick={e=>{
                        e.preventDefault();
                        setFound(null);
                        i18n.changeLanguage(found.language ?? found.languages).then();
                    }}>Switch</Button></div>
                </p>
            </div>}

           {info ? <div className={'d-flex'}>
               <div className={'flex-grow-1'}>
                   <DocumentView references={references} path={path} setPageNum={setPageNum} info={info}
                                 fileId={iFileId} highlight={highlight}/>
               </div>
           </div> : <div style={{minHeight:"82vh"}} className={'d-flex align-items-center justify-content-center'}>
               <p>
                   <div><span className={'bi bi-info-circle-fill text-danger'} style={{fontSize:"50px"}}></span></div>
                   Document requested is not available right now
                   <div><Button className={'mt-5 bg-greener rounded-pill'} variant={'success'} onClick={e=>{
                       e.preventDefault();
                       navigate('/');
                   }}>Go back to homepage</Button></div>
               </p>
           </div>}



        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(CaseNumberDocumentViewer);