import {BrowserRouter, Route, Routes} from "react-router-dom";
import GeneralLayout from "./layout/GeneralLayout";
import Homepage from "./pages/Homepage";
import LawsLayout from "./layout/LawsLayout";
import LawsInForce from "./pages/LawsInForce";
import LawsReports from "./pages/LawReports";
import JudgementReports from "./pages/JudgementReport";
import {I18nextProvider} from "react-i18next";
import i18n from "./i18n";
import AdvancedSearch from "./pages/AdvancedSearch";
import SearchResults from "./pages/SearchResults";
import TableOfContentViewer from "./components/TableOfContentViewer";
import ContactPage from "./pages/Contact";
import LegalNotices from "./pages/LegalNotices";
import ReactGA from 'react-ga';
import {useEffect} from "react";
import ScholarMaterials from "./pages/ScholarMaterials";
import LawsPrecedence from "./pages/LawPrecedence";
import CaseNumberDocumentViewer from "./components/case_number_document_viewer";
const TRACKING_ID = "G-34DT8C1292"; // OUR_TRACKING_ID

ReactGA.initialize(TRACKING_ID);

function App() {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
  return (
      <I18nextProvider i18n={i18n}>
      <BrowserRouter>
              <Routes>
                  <Route path="/" element={<GeneralLayout />}>
                      <Route path="" element={<Homepage />} />
                      <Route path="search" element={<AdvancedSearch showAdvanced={true}/>} />
                      <Route path="contact" element={<ContactPage />} />
                      <Route path="legal" element={<LegalNotices />} />
                      <Route path="search/results" element={<SearchResults />} />
                      <Route path="search/ad/results" element={<SearchResults showAdvanced={true}/>} />
                      <Route path="view/doc/:id/:fileId" element={<TableOfContentViewer fromJudge={false}/>} />
                      <Route path="view/doc_case/:caseNo" element={<CaseNumberDocumentViewer fromJudge={false}/>} />
                      <Route path="view/toc/doc/:id/:fileId" element={<TableOfContentViewer />} />
                      <Route path="laws" element={<LawsLayout />} >
                          <Route path={'in-force/:id'} element={<LawsInForce/>}/>
                          <Route path={'report/:id'} element={<LawsReports/>}/>
                          <Route path={'precedence/:id'} element={<LawsPrecedence/>}/>
                          <Route path={'scholar/:id'} element={<ScholarMaterials/>}/>
                          <Route path={'judgement/:id'} element={<JudgementReports/>}/>
                      </Route>
                  </Route>
              </Routes>
      </BrowserRouter>
      </I18nextProvider>
  );
}

export default App;
