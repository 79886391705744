import Container from "react-bootstrap/Container";
import {useTranslation} from "react-i18next";
import {Link, useLocation} from "react-router-dom";
import {Col, Row} from "react-bootstrap";

function FooterNavigation() {
    const {t} = useTranslation();
    let location = useLocation();
    let isHome = location.pathname === '/';
    return <div>
        {/*<div className={'d-flex justify-content-center mt-5'}>*/}
        {/*    <a href="https://facebook.com" className={'me-4'}>*/}
        {/*        <i className={'bi bi-facebook'} style={{fontSize:"40px"}}></i>*/}
        {/*    </a>*/}
        {/*    <a href="https://twitter.com" className={'me-4'}>*/}
        {/*        <i className={'bi bi-twitter'} style={{fontSize:"40px"}}></i>*/}
        {/*    </a>*/}
        {/*    <a href="https://youtube.com" className={'me-4'}>*/}
        {/*        <i className={'bi bi-youtube'} style={{fontSize:"40px"}}></i>*/}
        {/*    </a>*/}
        {/*    <a href="https://instagram.com" className={'me-4'}>*/}
        {/*        <i className={'bi bi-instagram'} style={{fontSize:"40px"}}></i>*/}
        {/*    </a>*/}
        {/*    <a href="https://linkedin.com" className={'me-4'}>*/}
        {/*        <i className={'bi bi-linkedin'} style={{fontSize:"40px"}}></i>*/}
        {/*    </a>*/}
        {/*</div>*/}
        <div className={'p-3 text-white mt-3'} style={{backgroundColor:"#20603D"}}>
            {isHome && <Row className={`justify-content-center py-3 g-0`} style={{backgroundColor:"#20603D"}}>
                <Col md={8} className={'m-0 g-0'}>
                    <Row className={`justify-content-between g-0`}>
                        <Col lg={4} className={'p-0'}>
                            <div>
                                <h5 style={{textAlign: 'left',marginLeft:"0px"}}>{t('quick_links')}</h5>
                                <ul style={{listStyleType: 'none', textAlign: 'left',paddingLeft:"0px"}}>
                                    <li className={'py-1'}><Link to='/laws/in-force/1' title={'Taxonomy of laws of the Republic of Rwanda'} className={'text-uppercase text-decoration-none text-white'}
                                                                 rel={'noreferrer'} style={{fontSize: "18px"}}> <i className={'bi bi-chevron-right'} style={{color: "#ffffff"}}></i> {t('judiciary')}</Link></li>
                                    <li className={'py-1'}><Link style={{fontSize: "18px"}} to="/laws/judgement/2" className={'text-uppercase text-decoration-none text-white'} title={'Legal system that is based on precedents, which are judicial decisions from previous cases. It\'s a cornerstone of common law systems in Rwanda.'}> <i className={'bi bi-chevron-right'} style={{color: "#ffffff"}}></i> RLRC</Link>
                                    </li>
                                    <li className={'py-1'}>
                                        <Link to={"/legal"} style={{fontSize:"18px"}} title={'Rwanda Law Reform Commission (RLRC) governed by the Law no 023/2024 of 25/01/2024 has the mission of analyzing laws in force and providing technical advice on draft bills with regard to both substance and drafting.'} className={'text-uppercase text-decoration-none text-white'}
                                              rel={'noreferrer'}> <i className={'bi bi-chevron-right'} style={{color: "#ffffff"}}></i> {t('legal_notices0')}</Link></li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={4} className={'d-flex justify-content-center-lg'}>
                            <div>
                                <h5 style={{textAlign: 'left'}}>{t('case_law_inquires')}</h5>
                                <ul style={{listStyleType: 'none', textAlign: 'left',paddingLeft:"0px"}}>
                                    <li>
                                        <div className={'mt-2 py-1'} style={{fontSize: "18px"}}>
                                            <i className={'bi bi-telephone-fill'} style={{color: "#ffffff"}}></i>
                                            <span className={'ms-3'}>3670/9040</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={'mt-2 py-1'} style={{fontSize: "18px"}}>
                                            <i className={'bi bi-envelope-fill'} style={{color: "#ffffff"}}></i>
                                            <span className={'ms-3'}>info@judiciary.gov.rw</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={'d-flex justify-content-start mt-2 py-1'} style={{color: "#20603D"}}>
                                            <a href="https://www.instagram.com/rwanda_lawreform/" target={"_blank"}
                                               rel={"noreferrer"} className={'me-4'}>
                                                <i className={'bi bi-instagram'}
                                                   style={{fontSize: "18px", color: "#ffffff"}}></i>
                                            </a>
                                            <a href="https://www.facebook.com/RwandaLawReformComission/" target={"_blank"}
                                               rel={"noreferrer"} className={'me-4'}>
                                                <i className={'bi bi-facebook'}
                                                   style={{fontSize: "18px", color: "#ffffff"}}></i>
                                            </a>
                                            <a href="https://www.youtube.com/@rwandalawreformcrlrc6944/videos" target={"_blank"} rel={"noreferrer"} className={'me-4'}>
                                                <i className={'bi bi-youtube'} style={{fontSize:"18px",color:"#ffffff"}}></i>
                                            </a>
                                            <a href="https://x.com/RwandaLawReform." target={"_blank"} rel={"noreferrer"}
                                               className={'me-4'}>
                                                <i className={'bi bi-twitter-x'}
                                                   style={{fontSize: "18px", color: "#ffffff"}}></i>
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={4}>
                            <div className={'d-flex justify-content-end-lg'}>
                                <div>
                                    <h5 style={{textAlign: 'left'}}>{t('legislation_inquires')}</h5>
                                    <ul style={{listStyleType: 'none', textAlign: 'left', paddingLeft:"0px"}}>
                                        <li>
                                            <div className={'mt-2 py-1'} style={{fontSize: "18px"}}>
                                                <i className={'bi bi-telephone-fill'} style={{color: "#ffffff"}}></i>
                                                <span className={'ms-3'}>1910</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className={'mt-2 py-1'} style={{fontSize: "18px"}}>
                                                <i className={'bi bi-envelope-fill'} style={{color: "#ffffff"}}></i>
                                                <span className={'ms-3'}>info@rlrc.gov.rw</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div className={'d-flex justify-content-start mt-2 py-1'} style={{color: "#20603D"}}>
                                                <a href="https://www.instagram.com/rwanda_lawreform/" target={"_blank"}
                                                   rel={"noreferrer"} className={'me-4'}>
                                                    <i className={'bi bi-instagram'}
                                                       style={{fontSize: "18px", color: "#ffffff"}}></i>
                                                </a>
                                                <a href="https://www.facebook.com/RwandaLawReformComission/" target={"_blank"}
                                                   rel={"noreferrer"} className={'me-4'}>
                                                    <i className={'bi bi-facebook'}
                                                       style={{fontSize: "18px", color: "#ffffff"}}></i>
                                                </a>
                                                <a href="https://www.youtube.com/@rwandalawreformcrlrc6944/videos" target={"_blank"} rel={"noreferrer"} className={'me-4'}>
                                                    <i className={'bi bi-youtube'} style={{fontSize:"18px",color:"#ffffff"}}></i>
                                                </a>
                                                <a href="https://x.com/RwandaLawReform." target={"_blank"} rel={"noreferrer"}
                                                   className={'me-4'}>
                                                    <i className={'bi bi-twitter-x'}
                                                       style={{fontSize: "18px", color: "#ffffff"}}></i>
                                                </a>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>}
            <div className={'d-flex justify-content-center'}>
                <div className={'text-start text-white'}>
                    &copy;{new Date().getFullYear()} {t('government')}
                </div>
            </div>
        </div>
    </div>
}

export default FooterNavigation;
