import LawComponent from "../components/LawComponent";
import {forceFlow} from "../constants";
import {useParams, useSearchParams} from "react-router-dom";
import {getQuery} from "../store/helpers/common_functions";

function LawsInForce() {
    const [searchParams] = useSearchParams();
    const activeChildQuery = searchParams.get('child');
    const activeGChildQuery = searchParams.get('g-child');

    const id = activeGChildQuery ?? activeChildQuery;
    return <div>
        <LawComponent flow={forceFlow} topValue={{
            value:id,
            key:'section'
        }} id={id} track={{value:id,label:'section',key:'section'}}/>
    </div>
}

export default LawsInForce;
