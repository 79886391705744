import { bindActionCreators } from "redux";
import {
    deleteAction,
    submitGeneralForm,
    systemLogin,
    fetchDetails,
    putAction,
    getLoggedUser,
    sendResetEmail,
    resetPassword,
    downloadPostBase64,
    getSections,
    getDocuments,
    resetDocuments
} from "../User/action";


export const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      SystemLogin: systemLogin,
      SendResetEmail: sendResetEmail,
      ResetPasswordAction: resetPassword,
      SubmitGeneralForm: submitGeneralForm,
      GetLoggedUser: getLoggedUser,
      DeleteAction: deleteAction,
      PutAction: putAction,
      FetchDetails: fetchDetails,
      GetSections: getSections,
      GetDocuments: getDocuments,
      ResetDocuments: resetDocuments,
      DownloadPostBase64: downloadPostBase64,
    },
    dispatch
  );
};
