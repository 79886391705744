import i18n from "i18next";
import {initReactI18next} from "react-i18next";

let lng = "en";

const find = localStorage.getItem("lang");

if (find === "rw" || find === 'fr') {
    lng = find;
}

i18n.use(initReactI18next).init({
    lng,
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
    resources: {
        en: {
            translation: {
                send_now: "Send now",
                full_text_search: "Full Text Search",
                legal_content: "All the content of this website is divided into two categories: legislation in force in Rwanda including international treaties and agreements ratified or acceded to by Rwanda and abrogated laws are property of Rwanda Law Reform Commission (RLRC) while Law reports and judgments are property of the Judiciary. These two are the only institutions allowed to make changes and updates on different features of this website."


                    + "<br>" +


                    "The laws contained in this website, in any way, do not modify the authenticity of the texts of laws published in the Official Gazette of the Republic of Rwanda and therefore cannot replace them."
                    + "<br>" +
                    "International treaties and agreements on this portal are that ratified or acceded to  by Rwanda as published in the Official Gazette of the Republic of Rwanda. Among them some  are  translated into Kinyarwanda, therefore,  they are in three languages: French, English and Kinyarwanda. Others are not yet translated into Kinyarwanda and such exercise is continuous."

                    + "<br>" +
                    "For each international treaty and agreement, it is indicated that it is translated into Kinyarwanda from one of the  mentioned languages or  both."

                    + "<br>" +

                    "It is also indicated for each international treaty and agreement available information relating to the place and the date of its adoption as well as to the way used by Rwanda to express its consent to be bound."


                    + "<br>" +
                    "International treaties and agreements ratified or acceded to  by Rwanda for which their texts are missing will be uploaded to this Web portal as long as they are available. The lists of such international instruments are available on RLRC ‘s website http://www.rlrc.gov.rw.",
                privacy_content: "RLRC and Judiciary do not collect your personal data when you visit our website unless you choose to provide them voluntarly. "
                    + "<br>" +
                    "All personal data provided are protected in accordance with Rwanda’s Law relating to the protection of personal data and privacy.",
                terms_content: "This website contains laws of Rwanda in force and abrogated as well as  international treaties and agreements ratified or acceded to by Rwanda.  Laws of Rwanda in force  means all legislation which creates rights and obligations, including laws adopted by Parliament, subsidiary legislations (orders), as well as regulations or directives of bodies with regulatory powers."


                    + "<br>" +
                    "Laws abrogated are put apart."
                    + "<br/><br/>" +

                    "These laws of Rwanda are classified in ten areas whithin which related international treaties and agreements are also classified as follows :"

                    + "<br>" +
                    " <b>Fundamental laws</b> comprise the Constitution of the Republic of Rwanda and other related laws, such as legislation related to administrative entities, and national symbols. They also include international instruments such as the Charter of United Nations, the Charter of African Union, etc."

                    + "<br>" +

                    "<b>Human rights laws</b> contain legislation on national human rights and international agreements ratified or acceded to by Rwanda such as the Universal Declaration of Human Rights, International Covenant on Civil and Political Rights, Convention Against Torture and other Cruel, Inhuman or Degrading Treatment or Punishment, African Charter on Human and Peoples’ Rights, etc."


                    + "<br>" +


                    "<b>Diplomatic and consular laws</b> include international agreements ratified or acceded to by Rwanda including the Convention on Special Missions, Vienna Convention on Diplomatic Relations (1961), Convention on the Privileges and Immunities of the Economic Community of the Great Lakes Countries and its Specialized Agencies, Additional Protocol to the OAU General Convention on Privileges and Immunities, Vienna Convention on the Representation of States in their Relations with International Organizations of a Universal Character (1975), East African Community Protocol on Foreign Policy Coordination, etc."

                    + "<br>" +


                    "<b>Criminal laws</b> include the law determining offences and penalties in general and specific criminal laws such as those punishing corruption, cyber-crimes, genocide ideology, trafficking in persons and exploitation of others, etc."


                    + "<br>" +

                    "<b>Security laws</b> comprise legislation related to security organs, security activities and security means. These include laws related to Rwanda Defence Forces, Rwanda National Police, National Intelligence and Security Services, interception of communication, arms, etc."


                    + "<br>" +

                    "<b>Judicial laws</b> contain legislation related to courts and procedures as well as other para judicial organs and their activities."

                    + "<br>" +
                    "<b>Administrative laws</b> include laws and orders related to administrative organization, governance, State organs, education, health, etc."

                    + "<br>" +
                    "<b>Taxation laws</b> comprise legislation related to tax payment and collection, those related to Rwanda Revenue Authority, value added tax, income tax, development levies, tax procedure, etc."


                    + "<br>" +
                    "<b>Civil laws</b> relate to laws on persons and management of natural resources such as legislation governing persons and family, Rwandan nationality, registration of the population, land, protection of environment, etc."

                    + "<br>" +
                    "<b>Business laws</b> contain laws and regulations related to financial and economic sectors such as banking organization, licensing requirements for banks, organization of micro-finance, insurance business organization, organization of commercial activities, competition and consumer protection, investment promotion, etc."

                    + "<br><br/>" +
                    "    Nevertheless, some legal instruments were not included in this collection for various reasons including the fact that they  are of individual, temporary, or limited application. Examples of such legal instruments are loan or grants agreements between Rwanda and another State or international organization or institution, national budget laws for a single fiscal year, laws creating temporary commissions, orders on appointment or revocation of State officials and public servants, those granting legal personality to non-governmental organizations or cooperatives, those allocating land in the State private domain for investment purposes, those determining the organizational structures of public institutions, etc."

                    + "<br>" +

                    "    The website  also contains both reported and unreported court decisions rendered by the courts in Rwanda."

                    + "<br>" +
                    "    Reported decisions are categorized according to the volumes of the Rwanda Law Report (RLR) published every quarter. Unreported decisions are categorized by the court that rendered the decision and the nature of the decision."

                    + "<br>" +
                    "    Both reported and unreported cases are categorized in different fields of law, including petitions seeking to declare law unconstitutional, public litigation petition, procedural claims, administrative, civil, criminal, and commercial cases."

                    + "<br><br/>" +


                    "    This website enables legal research, where one can find a case law by key words, case name, case number, and subject matter, date of judgment, volume of the report (reported decisions), court, and the judge who rendered the decision. All court decisions (Law reports or judgments) on this website have case notes and case summaries."


                    + "<br><br/>" +

                    "    RLRC is committed to update the content of the website by inserting new legislation or removing the repealed one this for both laws of Rwanda and international treaties and agreements."


                    + "<br><br/>" +


                    "©2024 Government of the Republic of Rwanda",
                follow_us: "Follow us on Social Platforms",
                service_terms: "Terms of Services",
                contact_judiciary: "For case laws, Contact Judiciary",
                contact_legislation: "For Legislation, Contact RLRC",
                search_keywords: "Search keywords here ...",
                message: "Your message",
                help: "How can we help you?",
                in_touch: "Get in Touch",
                search_name: "Search name here ...",
                fill_info: "Information to fill",
                legislation_inquires: "Legislation inquiries",
                find_words: "Find results including the following words",
                taxonomy: "Taxonomy of laws of the Republic of Rwanda",
                select: "Select",
                judiciary: "Judiciary",
                year: "Year",
                month: "Month",
                clear: "Clear Form",
                date: "Date",
                advanced_search: "Advanced Search",
                maxVoters: "Maximum Voters",
                maxBureaus: "Maximum Bureaus",
                pollingStation: "Polling Station",
                location: "Location",
                title: "Multi-language app",
                label: "Select another language!",
                about: "About Us",
                home: "Home",
                languages: "Languages",
                observers: "Observers",
                aboutInfo: "This website is comprised of laws of Rwanda, orders and regulations in force and not in force as well classified in the following ten domains: Fundamental laws, Human rights related laws, Diplomatic and consular laws, Criminal laws, Security laws, Judicial laws, administrative laws, Taxation laws, Civil laws, and Business laws. It also includes selected court decisions rendered by superior courts of Rwanda: Supreme Court, Court of Appeal, High Court and Commercial High Court.",
                portal: "Portal of Rwandan Laws and Case Laws",
                legal_info: "Access to all legal information of Rwanda",
                legal_notices: "Notice to Website Users",
                contact_us: "Contact Us",
                law_in_force: "Laws in force",
                law_reports: "Law Reports",
                legal_notices0: "Legal Notices",
                quick_links: "Quick Links",
                case_law_inquires: "Case law inquiries",
                government: "Republic of Rwanda",
                judgements: "Judgements",
                privacy_policy: "Privacy Policy",
                powered: "powered by",
                voterTransfers: "Voters transfer",
                voterList: "Voters list",
                signOut: "Sign Out",
                candidates: "Candidates",
                appPage: "Elections observers application page",
                category: "Category",
                domain: "Domains",
                section: "Section",
                local: "Local",
                international: "International",
                organization: "Organization",
                individual: "Individual",
                province: "Province",
                district: "District",
                phone: "Phone Number",
                email: "Email Address",
                nationalID: "Nation ID number",
                firstName: "First Name",
                lastName: "Last Name",
                passportNumber: "Passport Number",
                visaNumber: "VISA Number",
                issuedAt: "Issued At",
                nationality: "Nationality",
                gender: "Gender",
                expectedArrivalDate: "Expected Arrival Date",
                expectedDepartureDate: "Expecred Departure Date",
                submitApplication: "Submit Application",
                male: "Male",
                female: "Female",
                beforeTransfer: "Before Doing transfer, add your National ID",
                search: "Search for a word, an expression, or a reference...",
                voterInfo: "Voter Information",
                civilStatus: "Civil Status",
                birthDate: "Date of Birth",
                fatherNames: "Father names",
                motherNames: "Mother names",
                birthPlace: "Place of Birth",
                bioID: "Bio ID",
                ecn: "ECN",
                votingBureau: "Voting Bureau",
                eligibility: "Eligibility",
                securitySystemUser: "Security System User ID",
                completeTransferInfo: "Complete Transfer Info",
                sector: "Sector",
                cell: "Cell",
                village: "Village",
                submit: "Submit",
                candidateLogin: "Candidate Login",
                observerLogin: "Observer Login",
                password: "Password",
                login: "Log In",
                locationInfo: "Location Info",
                moreDetails: "More Details",
                nidNotFound: "National ID not found",
                legal_coverage: "All legal Information Coverage",
                legislation: "Legislation",
                case_laws: "Case laws",
                all_documents: "All documents",
                search_: "Search",
                search_d: "Search Documents",
                collection: "Collection",
                names: "Names",
                decision_status: "Decision status",
                volume: "Volume",
                filters: "Filters",
                filterByDate: "Filter by date range",
                startDate: "Start Date",
                endDate: "End Date",
                all: "All",
                public_notices_title: "Public Notice: Web Portal Upgrade",
                public_notices: "Rwanda Law Reform Commission (RLRC) governed by the Law no 023/2024 of 25/01/2024 has the mission of analyzing laws in force and providing technical advice on draft bills with regard to both substance and drafting.\n" +
                    "<br/>" +
                    "=\n" +
                    "<br/>" +
                    "This Wednesday, the 3rd of July 2024, we are pleased to announce that web portal www.amategeko.gov.rw, launched on 25th of November 2022, has been upgraded to provide you with an enhanced and more efficient experience. This upgrade includes several new features and improvements designed to better serve your needs.\n" +
                    "<br/>" +
                    "<b>Key features of the upgrade:\n</b>" +
                    "<br/>" +
                    "<br/>" +
                    "<b>1.\tDisplay of laws not in force\n</b>" +
                    "<br/>" +
                    "The legislation component has been categorized into “legislation in force” and “legislation not in force” because, even if a legislation is repealed, it may be used for circumstances which occurred during its existence, for research and academic purposes, etc.\n" +
                    "<br/>" +
                    "<b>2.\tSearch for specific language \n</b>" +
                    "<br/>" +
                    "A user now has the possibility to search a legislation or a case law and the content will be displayed in the language of his or her preference. For example, if a user chooses English, only the English version of the legislation or case law will be displayed. \n" +
                    "<br/>" +
                    "<br/>" +
                    "<b>3.\tHyperlinking \n</b>" +
                    "<br/>" +
                    "Hyperlinking allows users to navigate from one location to another through clickable links. These hyperlinks can connect to various types of resources web pages including documents, images, videos or specific sections within the same document. The legislation or case laws on the web portal contain hyperlinks which allow the user to click on a link of a given legislation number, then the link directs to that legislation. It is the same when a user clicks on a link of a given case number, some details of the case are then displayed.\n" +
                    "<br/>" +
                    "RLRC and the Judiciary are ready to assist you with any questions or issues you may encounter using the portal.\n" +
                    "For inquiries on legislation, please contact RLRC: \n" +
                    "<br/>" +
                    "E-mail: info@rlrc.gov.rw, Toll Free:1910.\n" +
                    "For inquiries on case laws, please contact the Judiciary: \n" +
                    "<br/>" +
                    "<br/>" +
                    "Email: info@judiciary.gov.rw, Toll Free:3670/9040.\n" +
                    "Done at Kigali, on 3rd of July 2024\n" +
                    "<br/>" +
                    "MUKANTAGANZWA Domitilla\n" +
                    "<br/>" +
                    "Chairperson of Rwanda Law Reform Commission \n"
            },
        },
        fr: {
            translation: {
                all: "Tout",
                quick_links: "Accès rapides",
                judiciary: "Judiciaire",
                full_text_search: "Recherche en texte intégral",
                startDate: "Date de début",
                endDate: "Date de fin",
                volume: "Volume",
                filterByDate: "Filtrer par plage de dates",
                filters: "Filtres",
                decision_status: "Statut de la décision",
                service_terms: "Conditions d'utilisation",
                clear: "Clear Form",
                collection: "Collection",
                legal_content: "Tout le contenu de ce site Web est divisé en deux catégories : la législation en vigueur au Rwanda y compris les traités et les accords internationaux ratifiés ou  dont l’accession est faite par le Rwanda et les loi abrogées, est la propriété de la Commission Rwandaise de la réforme du Droit (RLRC) tandis que les recueils des jugements et les jugements sont la propriété du pouvoir judiciaire. Ces deux institutions sont les seules autorisées à faire des modifications et des mises à jour sur les différentes fonctionnalités de ce site Web."


                    + "<br>" +

                    "En aucune façon, les lois contenues dans ce site Web, ne modifient l’authenticité des textes de lois publiés au Journal Officiel de la République du Rwanda et ne peuvent les remplacer."

                    + "<br>" +
                    "Les traités et les accords internationaux de ce site Web sont ceux ratifiés ou  dont l’accession est faite par le Rwanda tel que publié au Journal Officiel de la République du Rwanda. Parmi ceux-ci, il y en a qui sont  traduits en Kinyarwanda, et sont ainsi en trois langues : Français, Anglais et Kinyarwanda. D’autres ne sont pas encore traduits en Kinyarwanda et cet exercice est continuel."

                    + "<br>" +
                    "Il est indiqué pour chaque traité et accord international qu’il est traduit en Kinyarwanda de l’une de ces  langues mentionnées ou des deux à la fois."

                    + "<br>" +
                    "Il est aussi indiqué pour chaque traité et accord international l’information disponible relative au  lieu et  à la date de son adoption ainsi que au moyen par lequel le Rwanda a exprimé son consentement d’être lié."

                    + "<br>" +
                    "Les traités et les accords internationaux ratifiés ou  dont l’accession est faite par le  Rwanda et dont les textes font défaut seront téléchargés sur ce site web aussi longtemps qu’ils sont disponibles. Les listes de tels instruments internationaux  sont disponibles au site web du RLRC http://www.rlrc.gov.rw.",
                privacy_content: "RLRC et le pouvoir judiciaire ne collectent pas vos données personnelles lorsque vous visitez notre site Web, sauf si vous choisissez de les fournir volontairement."

                    + "<br>" +
                    "Toutes les données personnelles fourniées sont protégées conformément à la loi rwandaise relative à la protection des données personnelles et de la vie privée.",
                terms_content: "Ce site Web contient des lois du Rwanda en vigueur et abrogées ainsi que les traités et les accords internationaux ratifiés ou dont l’accession est faite par le Rwanda. Les lois du Rwanda en vigueur sont  toutes les lois qui créent des droits et des obligations, y compris les lois adoptées par le Parlement, les législations subsidiaires (arrêtés), ainsi que les règlements ou les directives des organes ayant le pouvoir règlementaire.\n" +
                    "<br>" +
                    "Les lois abrogées sont mises à part.\n" +
                    "<br>" +
                    "<br>" +
                    "<br/>" +
                    "Ces lois du Rwanda sont classées en dix domaines et c’est aussi dans ces mêmes domaines que sont classés les traités et les accords internationaux y relatifs de la manière suivante :\n" +
                    "<br>" +
                    "<b>Lois fondamentales</b> comprennent notamment la Constitution de la République du Rwanda et d’autres lois y relatives, telles que les lois relatives aux entités administratives, et aux symboles nationaux. Elles comprennent également les conventions internationales, telles que la Charte des Nations Unies, la Charte de l’Union Africaine, etc.\n" +
                    "<br/>" +
                    "<br>" +
                    "<b>Droits de la personne humaine</b> contiennent la législation sur les droits de la personne humaine et les accords internationaux ratifiés ou dont l’accession est faite par le Rwanda tels que la Déclaration universelle des droits de l’Homme, le Pacte International relatif aux Droits Civils et Politiques, la Convention contre la torture et d’autres peines ou des traitements cruels, inhumains ou dégradants, la Charte africaine des droits de l’homme et des peuples, etc.\n" +
                    "<br/>" +
                    "<br/>" +
                    "<br>" +
                    "<br/>" +
                    "<br/>" +
                    "<b>Lois relatives aux relations diplomatiques et consulaires</b> comprennent les accords internationaux ratifiés ou dont l’accession est faite par le Rwanda, notamment la Convention sur les missions spéciales, la Convention de Vienne sur les relations diplomatiques (1961), la Convention sur les privilèges et immunités de la Communauté Economique des Pays des Grands Lacs et de ses organismes spécialisés, le Protocole Additionnel à la Convention Générale sur les Privilèges et Immunités de l’Organisation de l’Unité Africaine, la Convention de Vienne sur la Représentation des États dans leurs relations avec les Organisations Internationales dе Caractère Universel (1975), le Protocole de la Communauté d’Afrique de l’Est sur la Coordination des Politiques Étrangères, etc.\n" +
                    "<br>" +
                    "<br/>" +
                    "<b>Législations pénales</b> comprennent la loi déterminant les infractions et les peines en général et les lois pénales spécifiques telles que celles portant répression de la corruption, de la cyber criminalité, de l’idéologie du génocide, de la traite des personnes et l’exploitation d’autrui, etc.\n" +
                    "<br/>" +
                    "<br>" +
                    "<br/>" +
                    "<b>Lois relatives à la sécurité</b> comprennent la législation relative aux organes de sécurité, aux activités de sécurité et aux moyens de sécurité. Il s’agit notamment de la législation concernant les Forces Rwandaises de Défense, la Police Nationale du Rwanda, le Service National de Renseignements et de Sécurité, l’interception des communications, les armes, etc.\n" +
                    "<br>" +
                    "<b>Lois judiciaires</b> comprennent la législation relative aux juridictions et à la procédure ainsi qu’aux autres organes para judiciaires et leurs activités.\n" +
                    "<br>" +
                    "<b>Lois administratives</b> comprennent les lois et les arrêtés relatifs  à l’organisation administrative, à la gouvernance, aux organes de l’État, à l’éducation, à la santé, etc.\n" +
                    "<br>" +
                    "<b>Lois fiscales</b> comprennent la législation relative à la collecte et au paiement des impôts, celles relatives à l’Office Rwandais des Recettes, à la taxe sur la valeur ajoutée, aux impôts sur le revenu, aux redevances de développement, aux procédures fiscales, etc.\n" +
                    "<br>" +
                    "<b>Lois civiles</b> comprennent les lois sur les personnes et sur la gestion des ressources naturelles telles que les lois sur les personnes et la famille, la nationalité rwandaise, l’enregistrement de la population, le régime foncier, la protection de l’environnement, etc.\n" +
                    "<br>" +
                    "<b>Lois des affaires</b> comprennent les lois et les règlements relatifs aux secteurs financiers et économiques tels que l’organisation bancaire, les conditions d’agrément des banques, l’organisation de la microfinance, l’organisation des activités d’assurance, l’organisation des activités commerciales, la concurrence et la protection des consommateurs, la promotion de l’investissement, etc.\n" +
                    "<br>" +
                    "Néanmoins, certaines législations ’ont  été excluses dans cette collection pour diverses raisons, notamment le fait  qu’elles soient  d’application individuelle, temporaire ou limitée. Des exemples de telles législations sont les accords de prêts ou de subventions entre le Rwanda et un autre État ou organization ou institution internationale , les lois de finances nationales pour un seul exercice, les lois créant des commissions temporaires, les arrêtés de nomination ou de révocation des agents de l’État et des fonctionnaires, celles conférant la personnalité juridique aux organisations non gouvernementales ou aux coopératives, celles qui octroient des terres du domaine privé de l’État à des fins d’investissement, celles qui déterminent les structures organisationnelles des établissements publics, etc.\n" +
                    "<br>" +
                    "Le site Web  contient également des décisions de justice publiées et non publiées rendues par les tribunaux du Rwanda.\n" +
                    "<br>" +
                    "Les décisions publiées sont classées selon les volumes de Rwanda Law Report (RLR) publié chaque trimestre. Les décisions non publiées sont classées par le tribunal qui a rendu la décision et la nature de la décision.\n" +
                    "<br>" +
                    "<br>" +
                    "Les cas signalés et non signalés sont classés dans différents domaines des lois, c’est-à-dire des requêtes visant à déclarer une loi non-constitutionnelle, des litiges publics, des réclamations procédurales, des affaires administratives, civiles, pénales et commerciales.\n" +
                    "<br>" +
                    "<br>" +
                    "<br/>" +
                    "Ce site Web permet une recherche juridique, où l’on peut trouver une jurisprudence par des mots clés, par nom du jugement, par numéro du jugement et par sujet, par date du jugement, par volume du rapport (décisions rapportées), par tribunal et par juge qui a pris la décision. Toutes les décisions de justice (rapports ou jugements) sur ce site Web contiennent des notes et des résumés.\n" +
                    "<br>" +
                    "<br>" +
                    "<br/>" +
                    "RLRC s’engage à mettre à jour le contenu du site Web en insérant de nouvelles législations ou en supprimant celles abrogées,  tant pour les lois du Rwanda que pour les traités et les accords internationaux. \n" +
                    "<br/>" +
                    "<br>" +
                    "<br>" +
                    "<br/>" +
                    "©2024 Gouvernement de la République du Rwanda\n",
                follow_us: "Suivez-nous sur les plateformes sociales",
                contact_judiciary: "Pour la jurisprudence, contactez le pouvoir judiciaire",
                send_now: "Envoyer maintenant",
                contact_legislation: "Pour la législation, contactez RLRC",
                search_keywords: "Recherchez des mots-clés ici...",
                search_name: "Recherchez le nom ici...",
                message: "Votre message",
                help: "Comment pouvons-nous vous aider?",
                in_touch: "Entrer en contact",
                fill_info: "Informations à remplir",
                year: "Annee",
                find_words: "Trouvez des résultats comprenant les mots suivants",
                month: "Mois",
                date: "Date",
                taxonomy: "Taxonomie des lois de la République du Rwanda",
                select: "Sélectionner",
                advanced_search: "Recherche Avancée",
                maxVoters: "Maximum d'électeurs",
                maxBureaus: "Bureaux maximum",
                pollingStation: "Bureau de vote",
                location: "Location",
                locationInfo: "Informations de localisation",
                nidNotFound: "Carte d'identité nationale introuvable",
                title: "Aplicación en varios idiomas",
                label: "Selecciona otro lenguaje!",
                about: "À propos de nous",
                home: "Page d'accueil",
                voterTransfers: "Transfert des électeurs",
                voterList: "Liste électorale",
                languages: "Langues",
                observers: "Observateurs",
                aboutInfo: "Ce site contient des lois, des arrêtés et des règlements en vigueur et abrogées au Rwanda, subdivisés en dix domaines suivants : Lois fondamentales, Droits de la personne humaine, Lois relatives aux relations diplomatiques et consulaires, Législations pénales, Lois relatives à la Sécurité, Lois judiciaires, Lois administratives, Lois fiscales, Lois civiles, et Lois des affaires. Il comprend également les décisions judiciaires sélectionnées rendues par les hautes juridictions du Rwanda: Cour Suprême, Cour d'appel, Haute Cour et Haute Cour de Commerce.",
                portal: "Portail des lois et jurisprudence du Rwanda",
                legal_info: "Accédez à toute l'information juridique du Rwanda",
                legal_notices: "Avis Aux Utilisateurs du Site Web",
                legal_notices0: "Mentions Légales",
                case_law_inquires: "Enquêtes sur la jurisprudence",
                legislation_inquires: "Demandes de renseignements sur la législation",
                contact_us: "Contactez-nous",
                law_in_force: "Lois en vigueur",
                law_reports: "Jurisprudence",
                judgements: "Jugements",
                privacy_policy: "Politique De Confidentialité",
                government: "République du Rwanda",
                powered: "alimenté par",
                signOut: "Se déconnecter",
                candidates: "Candidats",
                appPage: "Page de candidature pour les observateurs électoraux",
                category: "Catégorie",
                section: "Section",
                domain: "Domaines",
                local: "Locale",
                international: "Internationale",
                organization: "Organisation",
                individual: "Individual",
                province: "Province",
                district: "District",
                sector: "Secteur",
                cell: "Cellules",
                village: "Village",
                phone: "Numéro de téléphone",
                email: "Adresse e-mail",
                nationalID: "Numéro national d'identité",
                firstName: "Prénom",
                lastName: "Nom de famille",
                passportNumber: "Numéro de passeport",
                visaNumber: "Numéro de visa",
                issuedAt: "Émis à",
                nationality: "Nationalité",
                gender: "Genre",
                expectedArrivalDate: "Date d'arrivée prévue",
                expectedDepartureDate: "Date de départ prévue",
                submitApplication: "Présenter une demande",
                male: "Mâle",
                female: "Femelle",
                beforeTransfer: "Avant d'effectuer le transfert, ajoutez votre carte d'identité nationale",
                search: "Rechercher un mot, une expression ou une référence...",
                voterInfo: "Informations aux électeurs",
                civilStatus: "État civil",
                birthDate: "Date de naissance",
                fatherNames: "Noms de père",
                motherNames: "Noms de mère",
                birthPlace: "Lieu de naissance",
                bioID: "Bio ID",
                ecn: "ECN",
                votingBureau: "Bureau de vote",
                eligibility: "Admissibilité",
                securitySystemUser: "ID utilisateur du système de sécurité",
                completeTransferInfo: "Informations complètes sur le transfert",
                submit: "Soumettre",
                candidateLogin: "Connexion candidat",
                observerLogin: "Observer Login",
                password: "Mot de passe",
                login: "Connexion",
                moreDetails: "Plus de détails",
                legal_coverage: "Couverture de toutes les informations juridiques",
                case_laws: "Jurisprudence",
                all_documents: "Tous les documents",
                search_: "Recherche",
                legislation: "Législation",
                search_d: "Recherche documents",
                names: "Nom",
                public_notices_title: "Communiqué au Public : Mise à Niveau du Portail Web",
                public_notices: "La Commission du Rwanda de Réforme du Droit (RLRC) régie par la Loi no 023/2024 du 25/01/2024, a pour mission d`analyser les lois en vigueur et de donner des conseils techniques sur les avant- projets de lois concernant le fond et les aspects rédactionnels.\n" +
                    "<br/>" +
                    "<br/>" +
                    "Ce mercredi, le 3 juillet 2024, nous avons l’honneur d'annoncer que le portail web www.amategeko.gov.rw, lancé le 25 novembre 2022, a été mis à niveau en vue de vous offrir une expérience plus efficace et enrichie. Cette mise à niveau comprend plusieurs nouvelles fonctionnalités et améliorations conçues pour mieux répondre à vos besoins.\n" +
                    "<br/>" +
                    "<br/>" +
                    "<b>Caractéristiques principales de la mise à niveau :\n</b>" +
                    "<br/>" +
                    "<b>1.\tAffichage des lois abrogées</b> \n" +
                    "<br/>" +
                    "La composante législative a été catégorisée en « lois en vigueur » et « lois non en vigueur » car, même si une loi est abrogée, elle peut être utilisée pour des circonstances survenues pendant son existence, pour la recherche et des fins académiques, etc.\n" +
                    "<br/>" +
                    "<br/>" +
                    "<br/>" +
                    "<b>2.\tRecherche par langue spécifique</b> \n" +
                    "<br/>" +
                    "Un utilisateur a maintenant la possibilité de rechercher une loi ou une jurisprudence et le contenu sera affiché dans la langue de son choix. Par exemple, si un utilisateur choisit l’anglais, seule la version Anglaise de la loi ou de la jurisprudence sera affichée.\n" +
                    "<br/>" +
                    "<br/>" +
                    "<b>3.\tHyperliens</b>\n" +
                    "<br/>" +
                    "Les hyperliens permettent aux utilisateurs de naviguer d'un endroit à un autre via des liens cliquables. Ces hyperliens peuvent connecter à divers types de ressources des pages web y compris des documents, des images, des vidéos ou des sections spécifiques au sein du même document. Les lois ou les jurisprudences sur le portail web contiennent des hyperliens qui permettent à l'utilisateur de cliquer sur un lien d'un numéro de la loi donnée, puis le lien dirige vers cette loi. Il en va de même lorsqu'un utilisateur clique sur un lien d'un numéro de la jurisprudence ou du jugement donné, certains détails de la jurisprudence ou du jugement sont alors affichés.\n" +
                    "<br/>" +
                    "RLRC et le Pouvoir Judiciaire sont prêts à intervenir pour toute question ou problème que vous pourriez rencontrer en utilisant le portail.\n" +
                    "<br/>" +
                    "Pour toute question relative à la législation, veuillez contacter RLRC : \n" +
                    "E-mail: info@rlrc.gov.rw, Numéro gratuit: 1910. \n" +
                    "Pour toute question relative à la jurisprudence, veuillez contacter le Pouvoir Judiciaire :\n" +
                    " E-mail: info@judiciary.gov.rw, Numéro gratuit: 3670/9040\n" +
                    "Fait à Kigali, le 3 juillet 2024\n" +
                    "<br/>" +
                    "MUKANTAGANZWA Domitilla\n" +
                    "<br/>" +
                    "Présidente de la Commission du Rwanda de Réforme du Droit\n"
            },
        },
        rw: {
            translation: {
                all: "Byose",
                full_text_search: "Gushakisha ahantu hose",
                startDate: "Itariki yo gutangiriraho",
                legislation: "Amategeko",
                endDate: "Itariki yo kurangiriraho",
                filterByDate: "Yungurura ukurikije itariki",
                volume: "Volume",
                filters: "Muyunguruzi",
                decision_status: "Imiterere y'icyemezo",
                legal_content: "Ibiri kuri uru rubuga bigabanyijemo ibice bibiri: amategeko akoreshwa mu Rwanda harimo n’amasezerano mpuzamahanga u Rwanda rwemeje burundu cyangwa rwemeye kuba kimwe mu bihugu biyahuriyeho n’amategeko atagikoreshwa bifatwa nk’umutungo wa Komisiyo y’u Rwanda ishinzwe Ivugururwa ry’Amategeko (RLRC), mu gihe ibyegeranyo by’imanza n’imanza ubwazo bifatwa nk’umutungo w’Urwego rw’Ubucamanza. Izi nzego zombi ni zo zonyine zemerewe  guhindura no guhuza n’igihe imikoreshereze itandukanye y’uru rubuga.\n" +
                    "<br>" +
                    "Amategeko ari kuri uru rubuga ntashobora na rimwe guhindura ireme ry’amategeko yatangajwe mu Igazeti ya Leta ya Repubulika y’u Rwanda kandi ntashobora kuyasimbura. \n" +
                    "<br>" +
                    "Amasezerano mpuzamahanga ari  kuri uru rubuga ni ayo u Rwanda rwemeje burundu cyangwa rwemeye kuba kimwe mu bihugu biyahuriyeho nk’uko byatangajwe mu Igazeti ya Leta ya Repubulika y’u Rwanda. Muri yo harimo ayahinduwe mu Kinyarwanda bityo akaba ari mu ndimi eshatu: Igifaransa, Icyongereza n’Ikinyarwanda. Harimo kandi n’atarahindurwa mu Kinyarwanda uwo ukaba ari umukoro uzahoraho.\n" +
                    "<br>" +
                    "Buri masezerano mpuzamahanga yerekana ko yahinduwe mu Kinyarwanda avanwe muri rumwe muri izo ndimi zindi zavuzwe cyangwa muri zombi. \n" +
                    "<br>" +
                    "<br>" +
                    "Buri masezerano mpuzamahanga kandi yerekana amakuru aboneka yerekeranye n’ahantu n’igihe yemerejwe ndetse n’uburyo bwakoreshejwe kugira ngo u Rwanda rwemere kuyakurikiza.\n" +
                    "<br>" +
                    "<br>" +
                    "Amasezerano mpuzamahanga u Rwanda rwemeje burundu cyangwa rwemeye kuba kimwe mu bihugu biyahuriyeho ariko inyandiko zayo zikaba zitarashobora kuboneka azajya ashyirwa kuri uru rubuga uko izo nyandiko  zizajya ziboneka. Intonde z’ayo masezerano mpuzamahanga ziboneka ku rubuga rwa Komisiyo y’u Rwanda ishinzwe Ivugururwa ry’Amategeko http://www.rlrc.gov.rw.\n",
                service_terms: "Amasezerano ya Serivisi",
                follow_us: "Dukurikire kurubuga rusange",
                send_now: "Ohereza nonaha",
                collection: "Icyegeranyo",
                clear: "Hanagura",
                contact_legislation: "Kumategeko, Menyesha RLRC",
                contact_judiciary: "Ku manza, Menyesha Ubutegetsi bw’Ubucamanza",
                help: "Turagufasha! Twagufasha dute?",
                fill_info: "Amakuru yo kuzuza",
                in_touch: "Menyesha",
                message: "Ubutumwa bwawe",
                search_name: "Shakisha izina hano ...",
                search_keywords: "Shakisha ijambo ryibanze hano ...",
                year: "Umwaka",
                find_words: "Shakisha ibisubizo harimo amagambo akurikira",
                month: "Ukwezi",
                date: "Italiki",
                select: "Hitamo",
                taxonomy: "Amategeko ya Repubulika y’u Rwanda",
                advanced_search: "Gushakisha cyane",
                maxBureaus: "Umubare ntarengwa w'ibiro",
                maxVoters: "Umubare ntarengwa w'abatora",
                pollingStation: "Ibiro by'itora",
                location: "Ahantu",
                signOut: "Gusohoka",
                domain: "Indangarugero",
                candidates: "Abakandida",
                judiciary: "Ubucamanza",
                appPage: "Indorerezi zamatora aho zisabira",
                voterTransfers: "Kwimura abatora",
                voterList: "Urutonde rwabatora",
                title: "Applicazione multilingue",
                label: "Selezionare un'altra lingua ",
                about: "Ibitwerekeye",
                home: "Ahabanza",
                languages: "Indimi",
                observers: "Indorerezi",
                aboutInfo: "Uru rubuga ruriho amategeko, amateka n'amabwiriza akurikizwa n’ayakuweho mu Rwanda, agabanyijemo ibyiciro icumi bikurikira: Amategeko shingiro, Amategeko ajyanye n’Uburenganzira bwa muntu, Amategeko ajyanye n’Ububanyi n’amahanga, Amategeko mpanabyaha, Amategeko ajyanye n’Umutekano, Amategeko y’ubucamanza, Amategeko y’ubutegetsi, Amategeko y’imisoro, Amategeko mbonezamubano, Amategeko y’ubucuruzi. Ruriho kandi imanza zimwe na zimwe zatoranyijwe zaciwe n'inkiko nkuru zo mu Rwanda: Urukiko rw'ikirenga, Urukiko rw'ubujurire, Urukiko rukuru n'Urukiko rukuru rw'ubucuruzi.",
                portal: "Urubuga rw'amategeko n'imanza by'u Rwanda",
                legal_info: "Kugera ku makuru yose y'amategeko y'u Rwanda",
                legal_notices: "Imenyesha ku Bakoresha Urubuga",
                case_law_inquires: "Kubaza amategeko y'inkiko",
                legislation_inquires: "Kubaza amategeko",
                legal_coverage: "Amakuru Yose Yemewe",
                public_notices_title: "Itangazo Rigenewe Abantu Bose: Ivugururwa ry’Urubuga",
                public_notices: "Komisiyo y’u Rwanda ishinzwe Ivugururwa ry’Amategeko (RLRC) igengwa n’Itegeko no 023/2024 ryo ku wa  25/01/2024 ifite intego yo gusesengura amategeko ariho no gutanga inama zo mu rwego rwa tekiniki ku mbanzirizamishinga y’amategeko  haba ku ireme cyangwa mu myandikire.\n" +
                    "<br/>" +
                    "Kuri uyu wa Gatatu tariki ya 3 Nyakanga 2024 tunejejwe no kubamenyesha ko urubuga www.amategeko.gov.rw  rwatangijwe ku mugaragaro ku wa 25 Ugushyingo 2022, rwavuguruwe hagamijwe kugaragaza ubunararibonye bwisumbuye kandi burushijeho gutanga umusaruro. Iri vugururwa rikubiyemo ibintu byinshi bishya bigamije kurushaho gusubiza ibyifuzo byanyu.\n" +
                    "<br/>" +
                    "<b>Iby’ingenzi bikubiye muri iri vugururwa:\n</b>" +
                    "<br/>" +
                    "<b>1.\tKugaragaza amategeko yakuweho</b>\n" +
                    "<br/>" +
                    "Amategeko yashyizwe mu cyiciro cy’ « Amategeko ariho » n’icy’« Amategeko yakuweho » bitewe n’uko, n’ubwo itegeko ryaba ritagikoreshwa, rishobora gukoreshwa ku bintu byabayeho mu gihe ryari rigikoreshwa, ku mpamvu z’ubushakashatsi no kwigisha, n’izindi.\n" +
                    "<br/>" +
                    "<br/>" +
                    "<b>2.\tGushakisha ukoresheje ururimi rwihariye</b>\n" +
                    "<br/>" +
                    "Ukoresha uru rubuga ubu ashobora gushakisha itegeko cyangwa urubanza rwaciwe akabona ibikubiyemo mu rurimi yahisemo. Urugero, niba ukoresha urubuga ahisemo ururimi rw’Icyongereza, inyandiko y’itegeko cyangwa y’urubanza rwaciwe izagaragara mu Cyongereza gusa.\n" +
                    "<br/>" +
                    "<b>3.\t Ihuzamiyoboro</b>\n" +
                    "<br/>" +
                    "Gukoresha ihuzamiyoboro bituma ukoresha urubuga ashobora gushakisha avuye ahantu hamwe ajya ahandi akanze ku miyoboro. Iryo huzamiyoboro rishobora kugeza ku bintu bitandukanye biri ku rubuga harimo inyandiko, amashusho, videwo cyangwa ibyiciro byihariye mu nyandiko imwe. Itegeko cyangwa urubanza rwaciwe biri ku rubuga bifite ihuzamiyoboro rituma ukoresha urubuga  akanda kuri nimero y’itegeko runaka akarigeraho. Kimwe n’uko, iyo akanze kuri nimero y’urubanza rwaciwe, bimwe mu bigize urubanza bihita byigaragaza ku buryo burambuye.\n" +
                    "<br/>" +
                    "RLRC n’Ubutegetsi bw’Ubucamanza byiteguye kubafasha ku bibazo cyangwa imbogamizi mwahura na byo mu ikoresha ry’uru rubuga.\n" +
                    "Ku bibazo birebana n’amategeko, mwabaza RLRC: \n" +
                    "E-mail: info@rlrc.gov.rw, Nimero itishyura:1910.\n" +
                    "<br/>" +
                    "Ku bibazo birebana n’imanza zaciwe, mwabaza Ubutegetsi bw’Ubucamanza:\n" +
                    "<br/>" +
                    "Email: info@judiciary.gov.rw, Nimero itishyura: 3670/9040.\n" +
                    "<br/>" +
                    "Bikorewe i Kigali, ku wa 3 Nyakanga\n" +
                    "<br/>" +
                    "MUKANTAGANZWA Domitilla\n" +
                    "<br/>" +
                    "Perezida wa Komisiyo y’u Rwanda ishinzwe Ivugururwa ry’Amategeko\n",
                contact_us: "Twandikire",
                law_in_force: "Amategeko akurikizwa",
                law_reports: "Imanza ziri mu byegeranyo",
                government: "Repubulika y'u Rwanda",
                judgements: "Ibyemezo by'inkiko",
                privacy_policy: "Kugira Ibanga",
                powered: "ikoreshwa na",
                category: "Ikiciro",
                section: "Ikiciro",
                local: "Abanyarwanda",
                international: "Abanyamahanga",
                legal_notices0: "Amatangazo Yemewe",
                organization: "Ishirahamwe",
                individual: "Umuntu ku giti cye",
                province: "Intara",
                district: "Akarere",
                sector: "Umurenge",
                cell: "Akagari",
                village: "Umudugudu",
                phone: "Numero ya terefone",
                email: "Aderesi ya imeri",
                nationalID: "Inomero y'irangamuntu",
                firstName: "Izina Bwite",
                lastName: "Izina Ryanyuma",
                passportNumber: "Inomero ya pasiporo",
                visaNumber: "Inomero ya Visa",
                issuedAt: "Aho yatangiwe",
                nationality: "Ubwenegihugu",
                gender: "Igitsina",
                expectedArrivalDate: "Itariki yo Kuhagera",
                expectedDepartureDate: "Itariki yo Kugenda",
                submitApplication: "Ohereza Ubusabe",
                male: "Gabo",
                female: "Gore",
                beforeTransfer: "Mbere yo kwimuka, Shyiramo indangamuntu yawe",
                search: "Shakisha ijambo, imvugo, cyangwa ibisobanuro ...",
                voterInfo: "Amakuru yutora",
                civilStatus: "Irangamimerere",
                birthDate: "Itariki yavutseho",
                fatherNames: "Amazima ya se",
                motherNames: "Amazina ya nyina",
                names: "Amazina",
                birthPlace: "Aho yavukiye",
                bioID: "Bio ID",
                ecn: "ECN",
                votingBureau: "Ibiro by'itora",
                eligibility: "Aremerewe",
                securitySystemUser: "ID ya sisitemu y'umutekano",
                completeTransferInfo: "Uzuza Amakuru yo kwimuka",
                submit: "Ohereza",
                candidateLogin: "Aho abakandida binjirira",
                observerLogin: "Aho indorerezi zinjirira",
                password: "Ijambo Ry'ibanga",
                login: "Kwinjira",
                locationInfo: "Site yitora",
                moreDetails: "Andi makuru",
                nidNotFound: "Numero yirangamuntu ntabwo ibashije kuboneka",
                case_laws: "Ibyemezo by'Inkiko",
                all_documents: "Inyandiko zose",
                search_: "Shakisha",
                quick_links: "Umuyoboro wihuse",
                search_d: "Shakisha amadosiye",
                privacy_content: "RLRC n'Ubutegetsi bw’Ubucamanza ntibakusanya amakuru yawe bwite iyo usuye urubuga rwacu keretse uhisemo kuyatanga ku bushake. \n" +
                    "<br>" +
                    "Amakuru yose yatanzwe arindwa hakurikijwe amategeko y’u Rwanda ajyanye no kurinda amakuru bwite n’ibanga.\n",
                terms_content: "Uru rubuga ruriho amategeko y’u Rwanda akoreshwa n’atagikoreshwa ndetse  n’amasezerano mpuzamahanga u Rwanda rwemeje burundu cyangwa rwemeye kuba kimwe mu bihugu biyahuriyeho. Amategeko y’u Rwanda akoreshwa ni  amategeko yose ashyiraho uburenganzira n’inshingano, harimo amategeko yatowe n’Inteko Ishinga amategeko, amategeko y’ingereka (amateka) ndetse n’amabwiriza n’ibyemezo by’inzego zifite ububasha bwo gushyiraho amabwiriza.\n" +
                    "<br>" +
                    "Amategeko yavanyweho yashyizwe ukwayo.\n" +
                    "<br>" +
                    "<br>" +
                    "Aya mategeko y’u Rwanda ari mu ngeri icumi kandi ni na zo zikubiyemo amasezerano mpuzamahanga yerekeranye n’izo ngeri mu  buryo bukurikira:\n" +
                    "<br>" +
                    "<br>" +
                    "<b>Amategeko shingiro</b> arimo Itegeko Nshinga rya Repubulika y’u Rwanda n’andi mategeko ajyanye na ryo, nk’amategeko yerekeye inzego z’imitegekere y’igihugu n’ay’ibirango by’igihugu. Harimo kandi amasezerano mpuzamhanga, nk’Amasezerano ashyiraho Umuryango w’Abibumbye, Amasezerano mpuzamahanga ashyiraho Umuryango wa Afurika Yunze Ubumwe, n’andi.\n" +
                    "<br>" +
                    "<b>Amategeko ajyanye n’uburenganzira bwa muntu</b> arimo amategeko y’igihugu yerekeye uburenganzira bwa muntu n’amasezerano mpuzamahanga yemejwe burundu n’u Rwanda cyangwa rwemeye kuba kimwe mu bihugu biyahuriyeho, harimo nk’Itangazo mpuzamahanga ry’uburenganzira bwa muntu, Amasezerano mpuzamahanga yerekeye uburenganzira mu by’imbonezamubano na politiki, Amasezerano mpuzamahanga agamije kurwanya iyicarubozo n’ibindi bikorwa cyangwa ibihano by’ubugome, bya kinyamaswa cyangwa bitesha umuntu agaciro, Amasezerano Nyafurika yerekeye uburenganzira bwa muntu n’ubw’abaturage, n’andi.\n" +
                    "<br>" +
                    "<b>Amategeko ajyanye n’Ububanyi n’amahanga</b> akubiyemo amasezerano mpuzamahanga u Rwanda rwemeje burundu cyangwa rwemeye kuba kimwe mu bihugu biyahuriyeho, harimo Amasezerano mpuzamahanga yerekeye amatsinda yihariye y’abahagarariye ibihugu mu mahanga, Amasezerano mpuzamahanga y’i Vienne yerekeye imibanire hagati y’ibihugu (1961), Amasezerano mpuzamahanga yerekeye uburenganzira bwihariye n’ubudahangarwa by’Umuryango w’Ubukungu w’Ibihugu byo mu Karere k’Ibiyaga Bigari n’ibigo biwushamikiyeho, Amasezerano y’Inyongera ku masezerano rusange yerekeye uburenganzira bwihariye n’ubudahangarwa by’Umuryango w’Ubumwe bwa Afurika, Amasezerano mpuzamahanga y’i Vienne yo mu 1975 yerekeye ihagararirwa ry’ibihugu mu mibanire yabyo n’imiryango mpuzamahanga ifite imiterere nk’ihuriweho n’ibihugu byo ku isi ( 1975), Amasezerano y’Umuryango wa Afurika y’Iburasirazuba yerekeye guhuza politiki mpuzamahanga, n’ayandi.\n" +
                    "<br>" +
                    "<b>Amategeko mpanabyaha</b>, harimo itegeko rigena ibyaha n’ibihano muri rusange n’amategeko mpanabyaha yihariye, nk’ayerekeye ibyaha bijyanye na ruswa, ibyaha bikoreshejwe ikoranabuhanga, ingengabitekerezo ya jenoside, icuruzwa ry’abantu no gushakira inyungu mu bandi, n’andi.\n" +
                    "<br>" +
                    "<b>Amategeko ajyanye n’umutekano</b> arimo amategeko yerekeye inzego zishinzwe umutekano, ibikorwa n’ibikoresho bijyanye n’umutekano. Harimo amategeko yerekeye Ingabo z’u Rwanda, Polisi y’u Rwanda, Urwego rw’Igihugu rushinzwe Iperereza n’Umutekano, ayerekeye igenzura ry’itumanaho, ayerekeye intwaro, n’andi.\n" +

                    "<br>" +
                    "<b>Amategeko y’ubucamanza</b> akubiyemo amategeko ajyanye n’inkiko n’imiburanishirize, n’ajyanye n’izindi nzego zunganira ubucamanza n’ibikorwa byazo.\n" +
                    "<br>" +
                    "<b>Amategeko y’ubutegetsi</b> akubiyemo amategeko n’amateka ajyanye n’imitunganyirize y’ubutegetsi, imiyoborere, inzego za Leta, uburezi, ubuzima, n’ibindi.\n" +
                    "<br>" +
                    "<b>Amategeko y’imisoro</b> arimo amategeko ajyanye n’itangwa ry’imisoro n’isoresha, ayerekeye Ikigo cy’Imisoro n’Amahoro, umusoro ku nyongeragaciro, imisoro ku musaruro, amahoro yakwa ku bicuruzwa bigamije iterambere, uburyo bw’isoresha, n’ibindi.\n" +
                    "<br>" +
                    "<b>Amategeko mbonezamubano</b> arimo amategeko yerekeye abantu n’imicungire y’umutungo kamere, nk’agenga abantu n’umuryango, ubwenegihugu nyarwanda, iyandikwa ry’abaturage, agenga ubutaka, kubungabunga ibidukikije, n’andi.\n" +
                    "<br/>" +
                    "<br>" +
                    "<b>Amategeko y’ubucuruzi</b> arimo amategeko n’amabwiriza arebana n’inzego z’imari n’ubukungu, nk’imitunganyirize y’imirimo y’amabanki, ibisabwa mu kwemerera amabanki gukora, imitunganyirize y’imirimo y’imari iciriritse, imitunganyirize y’ubwishingizi, imitunganyirize y’imirimo y’ubucuruzi, ihiganwa mu bucuruzi no kurengera abaguzi, guteza imbere ishoramari, n’ibindi.\n" +
                    "<br>" +
                    "Icyakora, amategeko amwe n’amwe ntiyashyizwe muri iki cyegeranyo kubera  impamvu zitandukanye zirimo nko kuba  areba abantu ku giti cyabo, yarashyiriweho kumara igihe runaka cyangwa adakoreshwa ku bantu bose. Ingero z’ayo mategeko twavuga nk’amasezerano mpuzamahanga hagati y’u Rwanda  n’ikindi gihugu,  imiryango cyangwa ibigo mpuzamahanga arebana n’inguzanyo, impano, amategeko arebana n’ingengo y’imari y’igihugu y’umwaka, ashyiraho komisiyo zidahoraho, amateka ashyiraho cyangwa akuraho abayobozi n’abakozi ba Leta, atanga ubuzima gatozi ku miryango itari iya Leta cyangwa amakoperative, ashyira ubutaka mu mutungo bwite wa Leta ku mpamvu z’ishoramari, ashyiraho imbonerahamwe y’imyanya y’imirimo mu bigo bya Leta, n’ayandi.\n" +
                    "<br>" +
                    "<br>" +
                    "Uru rubuga ruriho kandi ibyemezo byafashwe n’inkiko byaba ibyatangajwe ndetse n’ibitaratangajwe.\n" +
                    "<br>" +
                    "<br/>" +
                    "Ibyemezo byatangajwe biri mu byiciro hagendewe ku mitumba y’ibyegeranyo by’ibyemezo by’inkiko bisohoka  buri gihembwe. Ibyemezo bitatangajwe bishyirwa mu byiciro hagendewe ku rukiko rwafashe icyemezo n’imiterere y’icyemezo.\n" +
                    "<br/>" +
                    "Ibyemezo by’inkiko byatangajwe n’ibitaratangajwe biri mu byiciro bitandukanye by’amategeko, birimo icyiciro cy’ibirego bisaba kwemeza ko Itegeko rinyuranyije n’Itegeko Nshinga, ibirego birimo Leta, ibijyanye n’imiburanishirize, ibirego by’imanza z’ubutegetsi, iz’imbonezamubano, iz’impanabyaha, n’iz’ubucuruzi.\n" +
                    "<br>" +
                    "Uru rubuga rufasha gukora ubushakashatsi mu byerekeranye n’amategeko, aho umuntu ashobora kubona urubanza rwaciwe akoresheje amagambo shingiro yarwo, izina ry’urubanza, numero y’urubanza, n’icyiciro cyarwo, itariki rwaciriweho, numero y’umutumba (ku byemezo byatangajwe), urukiko, umucamanza wafashe icyemezo. Ibyemezo by’inkiko byose (ibyatangajwe n’ibitaratangajwe) kuri uru rubuga bifite ibisobanuro n’incamake.\n" +
                    "<br>" +
                    "<br>" +
                    "Komisiyo y’u Rwanda ishinzwe Ivugururwa ry’Amategeko yiyemeje guhora ihuza  n’igihe amategeko y’u Rwanda kimwe n’amasezerano mpuzamahanga biri kuri uru rubuga yongeramo amashya cyangwa ikuramo ayavuyeho ibyo kandi bigakorwa no ku masezerano mpuzamahanga.\n" +
                    "<br/>" +
                    "<br>" +
                    "<br>" +
                    "©2024 Guverinoma ya Repubulika y’u Rwanda\n"
            },
        },
    },
});

export default i18n;
