import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {mapStateToProps} from "../store/helpers/mapState";
import {mapDispatchToProps} from "../store/helpers/mapDispatch";
import {Spinner} from "react-bootstrap";

const HtmlDocumentViewer = ({FetchDetails,SubmitGeneralForm,id}) => {

    const [html, setHtml] = useState("");
    const [loading,setLoading] = React.useState(true);



    const loadPath = async ()=>{
        setLoading(true);
       const resp = await SubmitGeneralForm(`documents-files/html/${id}`,{});
        if (resp.status) {
            const newResp = await FetchDetails(resp.data.data.path);
            if(newResp.status){
                setHtml(newResp.data);
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        if(id) {
            loadPath().then();
        }
    }, [FetchDetails,id]);


    return loading ? <div className={'d-flex hover h2 align-items-center justify-content-center vh-100'}>
        <Spinner/>
    </div> : <div dangerouslySetInnerHTML={{__html:html}}></div>
}

export default connect(mapStateToProps,mapDispatchToProps)(HtmlDocumentViewer);