import CustomNavBar from "../Navbar";
import Container from "react-bootstrap/Container";
import FooterNavigation from "../FooterNav";
import {Outlet, useLocation} from "react-router-dom";
import {connect} from "react-redux";
import {mapStateToProps} from "../store/helpers/mapState";
import {mapDispatchToProps} from "../store/helpers/mapDispatch";
import {ToastContainer} from "react-toastify";
// import {useEffect} from "react";

function GeneralLayout() {
    // useEffect(()=>{
    //     GetSections();
    // },[GetSections]);
    const style = {minHeight: "75vh", marginTop: "75px"};
    const location = useLocation();
    return <div>
        <div className="App">
            <ToastContainer/>
            <CustomNavBar/>
            {location.pathname.includes("view/doc") || location.pathname.includes("view/toc/doc") || location.pathname === "/" ? <div style={style}>
                <Outlet/>
            </div> : <Container style={{minHeight: "75vh", marginTop: "80px"}}>
                <Outlet/>
            </Container>}
            <FooterNavigation/>
        </div>
    </div>
}


export default connect(mapStateToProps,mapDispatchToProps)(GeneralLayout);
