import {Button, Card, Col, FormGroup, Row} from "react-bootstrap";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {mapStateToProps} from "../store/helpers/mapState";
import {connect} from "react-redux";
import {mapDispatchToProps} from "../store/helpers/mapDispatch";
import {useEffect, useState} from "react";
import {inArray} from "../store/helpers/common_functions";
import {monthList} from "../constants";
import {Helmet} from "react-helmet";

function AdvancedSearch({sections,FetchDetails,partial,showAdvanced,currentSection,onFilter}) {
    const navigate = useNavigate();
    const [categories,setCategories] = useState([]);
    const [collections,setCollections] = useState([]);
    const [subCategories,setSubCategories] = useState([]);
    const [text,setText] = useState("");
    const [section,setSection] = useState("");
    const [category,setCategory] = useState("");
    const [collection,setCollection] = useState("");
    const [subCategory,setSubCategory] = useState("");
    const [year,setYear] = useState("");
    const [month,setMonth] = useState("");
    const [date,setDate] = useState("");
    const [keyword,setKeyword] = useState("");
    // const [search, setSearch] = useState("");
    const [volume,setVolume] = useState("");
    const [status,setStatus] = useState("");
    const [volumes,setVolumes] = useState([]);
    // const [advanced, setAdvanced] = useState(0);
    const [decisionStatus,setDecisionStatus] = useState([]);
    const {t,i18n} = useTranslation();
    const [searchParams] = useSearchParams();
    // const qText = searchParams.get("text");
    const qSearch = searchParams.get("search");
    const qKeyword = searchParams.get("keyword");
    const qSection = searchParams.get("section");
    const qCategory = searchParams.get("category");
    const qSubCategory = searchParams.get("sub_category");
    const qVolume = searchParams.get("volume");
    const qYear = searchParams.get("year");
    const qMonth = searchParams.get("month");
    const qStatus = searchParams.get("status");
    const qDate = searchParams.get("date");
    const qAdvanced = searchParams.get("advanced");
    const qCollection = searchParams.get("collection");

    const getQSearch = ()=>qSearch ? `&search=${encodeURIComponent(qSearch)}` : '';

    let years = [];
    let dates = [];
    let currentYear = new Date().getFullYear();
    for (let i = 10;i>=0;i--){
        years.push(currentYear-i);
    }
    for (let i = 1;i<=31;i++){
        dates.push(i);
    }

    const loadCategories = async ()=>{
        const data = await FetchDetails(`dropdowns/categories?language=${i18n.language}${getQSearch()}`);
        if(data.status){
            setCategories(data.data);
        }
    }
    const loadCollections = async ()=>{
        const data = await FetchDetails(`dropdowns/collections?language=${i18n.language}${getQSearch()}`);
        if(data.status){
            setCollections(data.data);
        }
    }

    const loadSubCategories = async ()=>{
        const data = await FetchDetails(`dropdowns/sub-categories?language=${i18n.language}&category=${category}${getQSearch()}`);
        if(data.status){
            setSubCategories(data.data);
        }
    }

    const loadVolumes = async ()=>{
        const data = await FetchDetails(`dropdowns/volumes?language=${i18n.language}${getQSearch()}`);
        if(data.status){
            setVolumes(data.data);
        }
    }

    const loadDecisionStatus = async ()=>{
        const data = await FetchDetails(`dropdowns/decisions-status?language=${i18n.language}${getQSearch()}`);
        if(data.status){
            setDecisionStatus(data.data);
        }
    }

    useEffect(()=>{
        loadCategories().then();
        loadVolumes().then();
        loadCollections().then();
        loadDecisionStatus().then();
    },[qSearch,i18n.language]);

    useEffect(()=>{
        if(category) {
            setSubCategories([]);
            loadSubCategories().then();
        }
    },[category]);

    useEffect(()=>{
        if(qSearch){
            setText(qSearch);
        }
        if(qKeyword){
            setKeyword(qKeyword);
        }
        if(qSection){
            setSection(qSection);
        }
        if(qCategory){
            setCategory(qCategory);
        }
        if(qVolume){
            setVolume(qVolume);
        }
        // if(qSearch){
        //     setSearch(qSearch);
        // }
        if(qStatus){
            setStatus(qStatus);
        }
        if(qYear){
            setYear(qYear);
        }
        if(qMonth){
            setMonth(qMonth);
        }
        // if(qAdvanced){
        //     setAdvanced(Number(qAdvanced));
        // }
        if(qSubCategory){
            setSubCategory(qSubCategory);
        }
        if(qDate){
            setDate(qDate);
        }
        if(qCollection){
            setCollection(qCollection);
        }
    },[qKeyword,qSection,qCategory,qVolume,qYear,qSubCategory,qMonth,qDate,qCollection]);


    let realSection = currentSection ?? section;

    const renderSection = (sections,col)=>{
        return sections?.map((v)=>{
            let label;
            switch (i18n.language){
                case "en":
                    label = v['name_en'];
                    break;
                case "fr":
                    label = v['name_fr'];
                    break;
                case "rw":
                    label = v['name_rw'];
                    break;
                default:
                    label = "";
            }

            const hasChildren = v.children?.length>0;
            return <div className={'mb-2'}>
                        {!hasChildren && <>
                            <label className={'d-flex align-items-center'}>
                            <input type="checkbox" name={'section'} value={v.id} onChange={e =>  setSection(e.target.checked ? e.target.value : null)}
                                   checked={v.id.toString() === realSection}/>
                            <span className={'ms-1'}>{label}</span></label>
                        </>}
                    {hasChildren && <div className={'mb-1'}>
                        {renderSection(v.children)}
                    </div>}
                </div>
        })
    }


    const categoryChild = categories.map(v => {
        let label = v.label;

        return <div className={'mb-1'}>
            <div className={'d-flex'}>
                <label className={'flex-grow-1'}><input type="checkbox" name={'category'}
                              onChange={e =>{
                                  const value = e.target.checked ? e.target.value : null;
                                  setCategory(value);
                                  if(!showAdvanced && typeof  onFilter === 'function') {
                                      onFilter({
                                          category:value
                                      })
                                  }
                              }}
                              value={v.value}
                              checked={v.value.toString() === category}/> {label}
                </label>
                {partial && <div>
                    <span className={'badge bg-success ms-2'}>{v.count}</span>
                </div>}
            </div>

            {subCategories?.length>0 && <div className={'ms-3'}>
                {v.value.toString() === category && subCategories.map(sub => {
                    let label2 = sub.label;
                    return <div>
                        <div className={'d-flex'}>
                            <label className={'flex-grow-1'}>
                                <input type="checkbox"
                                          name={'sub_category'}
                                          onChange={e => {
                                              const value = e.target.checked ? e.target.value : null;
                                              setSubCategory(value);
                                              if(!showAdvanced && typeof  onFilter === 'function') {
                                                  onFilter({
                                                      subCategory:value
                                                  })
                                              }
                                          }
                                          }
                                          value={sub.value}
                                          checked={sub.value.toString() === subCategory}/> {label2}
                            </label>
                            {partial && <div>
                                <span className={'badge bg-success ms-2'}>{sub.count}</span>
                            </div>}
                        </div>
                    </div>
                })}
            </div>}
        </div>
    });

    const collectionChild = collections.map(v => {
        let label2 = v.label;
        return <span className={'d-flex mb-1'}>
            <label
                className={'d-flex align-items-center flex-grow-1'}>
                <input type="checkbox" name={'collection'}
                       value={v.value}
                       onChange={e =>{
                           const value = e.target.checked ? e.target.value : null;
                           setCollection(value);
                           if(!showAdvanced && typeof  onFilter === 'function') {
                               onFilter({
                                   collection:value
                               })
                           }
                       }}
                       checked={v.value.toString() === collection}/>
                <span className={'ms-1'}>{label2}</span></label>
            {partial && <div>
                <span className={'badge bg-success ms-2'}>{v.count}</span>
            </div>}
        </span>
    });

    const volumesChild = volumes.map(v => {
        let label = v.label;
        return <div className={'d-flex mb-1'}>
            <label className={'flex-grow-1'}><input type="checkbox" value={v.value}
                                  name={'volume'}
                                  checked={v.value.toString() === volume}
                                  onChange={e => {
                                      const value = e.target.checked ? e.target.value : null;
                                      setVolume(value);
                                      if(!showAdvanced && typeof  onFilter === 'function') {
                                          onFilter({
                                              volume:value
                                          })
                                      }

                                  }}/> {label}
        </label>
            {partial && <div>
                <span className={'badge bg-success ms-2'}>{v.count}</span>
            </div>}
        </div>
    });

    const decisionStatusChild = decisionStatus.map(v => {
        let label = v.label;
        return <div className={'d-flex'}>
            <label className={'flex-grow-1'}><input type="checkbox" value={v.value}
                                  name={'status'}
                                  checked={v.value.toString() === status}
                                  onChange={e => {
                                      const value = e.target.checked ? e.target.value : null;
                                      setStatus(value);
                                      if(!showAdvanced && typeof  onFilter === 'function') {
                                          onFilter({
                                              status:value
                                          })
                                      }
                                  }}/> {label}
        </label>
            {partial && <div>
                <span className={'badge bg-success ms-2'}>{v.count}</span>
            </div>}
        </div>
    });

    const hasCollection = inArray(["2.1", "2.2.1", "2.2.2", "2.2.3","2","2.2"], realSection);
    const hasCategory = inArray(["1.1", "1.2","1"], realSection);
    const hasVolume = inArray(["2.2.1","2.2"], realSection);
    const hasDecision = inArray(["2.1","2"], realSection);
    return <div>
        <Helmet>
            <title>Advanced Search</title>
            <meta name="description" content="Search for a word, an expression, or a reference..." />
            <link rel="canonical" href="https://www.amategeko.gov.rw/search" />
        </Helmet>
        {showAdvanced ? <form action="/search/results" method={"get"} onSubmit={(event) => {
            event.preventDefault();
            const data = new FormData(event.target);
            let formObject = Object.fromEntries(data.entries());
            navigate(`/search/results/?${Object.keys(formObject).map(v => formObject[v] ? `${v}=${encodeURIComponent(formObject[v])}` : null).filter(v => v).join("&")}`);
        }}>
            <Row className={'justify-content-center'}>
                <Col xl={partial ? 12 : 7}>
                    <div className={'mb-5 mt-4'}>
                        <Card className={'shadow-sm'} style={{backgroundColor: "#F5F5F5"}}>
                            <div className={'card-body text-start'}>
                                <div className={'d-flex'}>
                                    {!partial && <div className={'px-5 py-3'}>
                                        <h4>{t('fill_info')}</h4>
                                    </div>}
                                    <div className={'flex-grow-1'}>
                                        <div className={'mb-3'}>
                                            <label htmlFor={'search-field'}
                                                   className={'mb-3 fw-bold'}>{t('find_words')}</label>
                                            <input name={'search'} value={text} onChange={e => setText(e.target.value)}
                                                   className={'form-control'} id={'search-field'}
                                                   placeholder={t('search')}/>
                                        </div>
                                        {/*<div className={'mb-3'}>*/}
                                        {/*    <input name={'keyword'} value={keyword}*/}
                                        {/*           onChange={e => setKeyword(e.target.value)} className={'form-control'}*/}
                                        {/*           id={'search-keyword'} placeholder={t('search_keywords')}/>*/}
                                        {/*</div>*/}

                                        {/*<div className={advanced === 1 ? 'mb-3' : ''}>*/}
                                        {/*    <label htmlFor="advanced">*/}
                                        {/*        <input type="checkbox" id={'advanced'} name={'advanced'} value={advanced} checked={advanced === 1} onChange={e=>{*/}
                                        {/*            setAdvanced(e.target.checked ? 1 : 0);*/}
                                        {/*        }}/>*/}
                                        {/*        <span className={'ms-2'}>{t('full_text_search')}</span>*/}
                                        {/*    </label>*/}
                                        {/*</div>*/}
                                        {/*{advanced === 1 && <div>*/}
                                        {/*    <input name={'search'} value={search}*/}
                                        {/*           onChange={e => setSearch(e.target.value)} className={'form-control'}*/}
                                        {/*           id={'search-full'} placeholder={t('search')}/>*/}
                                        {/*</div>}*/}
                                    </div>
                                </div>

                            </div>
                        </Card>
                        <Card className={'shadow-sm mt-5'} style={{backgroundColor: "#F5F5F5"}}>
                            <div className={'card-body text-start'}>
                                <div className={'d-flex'}>
                                    {!partial && <div className={'px-5 py-3'}>
                                        <h4>{t('domains')}</h4>
                                    </div>}
                                    <div className={'flex-grow-1'}>
                                        <div className={'mb-3'}>
                                            {/*<FormLabel className={'fw-bold'}>Sections</FormLabel>*/}
                                            <Row>
                                                <Col md={6}>
                                                    <h5 className={'fw-bold'}>{t('section')}</h5>
                                                    {renderSection(sections)}
                                                </Col>
                                                <div className={'col-md-6'}>

                                                    {(hasCategory || (!realSection && partial))  && <>
                                                        <div className={'mb-3'}>
                                                            <h5 className={'fw-bold'}>{t('category')}</h5>
                                                            {categoryChild}
                                                        </div>
                                                    </>}
                                                    {(hasCollection || (!realSection  && partial)) &&
                                                        <div className={'mb-3'}>
                                                            <FormGroup>
                                                                <h5 className={'fw-bold'}>{t('collection')}</h5>
                                                                <div>
                                                                    {collectionChild}
                                                                </div>
                                                            </FormGroup>
                                                        </div>}

                                                </div>
                                            </Row>
                                        </div>
                                        <Row>
                                            <Col md={12}>
                                                {/*<div className={'d-flex mb-3'}>*/}
                                                {/*    <div className={'flex-grow-1'}>*/}
                                                {/*        <FormGroup>*/}
                                                {/*            <FormLabel>Min Date</FormLabel>*/}
                                                {/*            <input type="date" className={'form-control form-control-sm'} placeholder={'Max Date'}/>*/}
                                                {/*        </FormGroup>*/}
                                                {/*    </div>*/}
                                                {/*    <div style={{width:"10px"}}></div>*/}
                                                {/*    <div className={'flex-grow-1'}>*/}
                                                {/*        <FormGroup>*/}
                                                {/*            <FormLabel>Max Date</FormLabel>*/}
                                                {/*            <input type="date" className={'form-control form-control-sm'} placeholder={'Max Date'}/>*/}
                                                {/*        </FormGroup>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                <div className={'mb-3'}>
                                                    <Row>
                                                        <Col md={4}>
                                                            <FormGroup>
                                                                <select onChange={e => setYear(e.target.value)}
                                                                        name="year" id="text" value={year}
                                                                        className={'form-control form-control-sm'}>
                                                                    <option value="">-- {t('year')} --</option>
                                                                    {years.map(v => <option
                                                                        value={v}>{v}</option>)}
                                                                </select>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={4}>
                                                            <FormGroup>
                                                                <select disabled={!year}
                                                                        onChange={e => setMonth(e.target.value)}
                                                                        name="month" id="month" value={month}
                                                                        className={'form-control form-control-sm'}>
                                                                    <option value="">-- {t('month')} --</option>
                                                                    {monthList.map(v => {

                                                                        let label = v.name_en;
                                                                        switch (i18n.language) {
                                                                            case "fr":
                                                                                label = v.name_fr;
                                                                                break;
                                                                            case "rw":
                                                                                label = v.name_rw;
                                                                                break;
                                                                            default:
                                                                                    label = v.name_en;
                                                                                    break;
                                                                        }
                                                                        return <option
                                                                            value={v.id}>{label}</option>;
                                                                    })}
                                                                </select>
                                                            </FormGroup>
                                                        </Col>
                                                        <Col md={4}>
                                                            <FormGroup>
                                                                <select disabled={!month || !year}
                                                                        onChange={e => setDate(e.target.value)}
                                                                        name="date" id="date" value={date}
                                                                        className={'form-control form-control-sm'}>
                                                                    <option value="">-- {t('date')} --</option>
                                                                    {dates.map(v => <option
                                                                        value={v}>{v}</option>)}
                                                                </select>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                {hasVolume && <>
                                                    <div className={'mb-3'}>
                                                        <h5>{t('volume')}</h5>
                                                        {volumesChild}
                                                    </div>
                                                </>}
                                                {(hasDecision || (!realSection && partial)) && <>
                                                    <div className={'mb-3'}>
                                                        <h5>{t('decision_status')}</h5>
                                                        {decisionStatusChild}
                                                    </div>
                                                </>}
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </Card>
                        <div className={'mt-3 text-end'}>
                            <Button type={'reset'} onClick={() => navigate('/search')} variant={'outline-success'}
                                    className={'me-2'}>{t('clear')}</Button>
                            <Button variant={'success'} type={'submit'}
                                    style={{backgroundColor: "#20603D"}}>{t('search_d')}</Button>
                        </div>
                    </div>
                </Col>
            </Row>
        </form> : <div className={'shadow-sm border p-3 rounded text-start'}>
            <h4 className={'mb-3'}>{t('filters')}</h4>

            <h6 className={'mb-0'}>{t('filterByDate')}</h6>

            <div className={'row'}>
                <div className={'col-md-6'}>
                    <div className={'mb-3'}>
                        <label htmlFor="" className={'col-form-label-sm fw-bold'}>{t('startDate')}</label>
                        <input type="date" className={'form-control form-control-sm'} onChange={(e)=>{
                            const value = e.target.value;
                            if(typeof onFilter === 'function') {
                                onFilter({
                                    startDate:value
                                })
                            }
                        }}/>
                    </div>
                </div>
                <div className={'col-md-6'}>
                    <div className={'mb-3'}>
                        <label htmlFor="" className={'col-form-label-sm fw-bold'}>{t('endDate')}</label>
                        <input type="date" className={'form-control form-control-sm'} onChange={(e)=>{
                            const value = e.target.value;
                            if(typeof onFilter === 'function') {
                                onFilter({
                                    endDate:value
                                })
                            }
                        }}/>
                    </div>
                </div>
            </div>

            {(hasCategory || !currentSection) && <div>
                <div><label htmlFor="" style={{fontFamily:'Raleway-2',fontSize:'16px'}}>{t('category')}</label></div>
                {categoryChild}
            </div> }
            {(hasCollection || !currentSection) && <div>
                <div><label htmlFor="" style={{fontFamily:'Raleway-2',fontSize:'16px'}}>{t('collection')}</label></div>
                {collectionChild}
            </div>}
            {(hasVolume || !currentSection) && <div>
                <div><span style={{fontFamily:'Raleway-2',fontSize:'16px'}}>{t('volume')}</span></div>
                {volumesChild}
            </div>}
            {(hasDecision || !currentSection) && <div>
                <div><label htmlFor="" style={{fontFamily:'Raleway-2',fontSize:'16px'}}>{t('decision_status')}</label></div>
                {decisionStatusChild}
            </div>}
        </div>}
    </div>;
}

export default connect(mapStateToProps,mapDispatchToProps)(AdvancedSearch);
