import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {mapStateToProps} from "../store/helpers/mapState";
import {connect} from "react-redux";
import {mapDispatchToProps} from "../store/helpers/mapDispatch";
import {useEffect, useState} from "react";
import AOS from "aos";
import "./../../node_modules/aos/dist/aos";
import "./../../node_modules/aos/dist/aos.css";
import CountUp from "react-countup";
import {Helmet} from "react-helmet";

function Homepage({sections,FetchDetails}) {
    const {t,i18n} = useTranslation();
    const [data, setData] = useState();

    const loadData = async () =>{
       const resp = await FetchDetails(`statistics/counts?language=${i18n.language}`);
       if(resp.status){
           setData(resp.data);
       }
    }
    useEffect(() => {
        AOS.init({
            startEvent: "DOMContentLoaded",
            disable: function () {
                var maxWidth = 996;
                return window.innerWidth < maxWidth;
            },
            throttleDelay: 10,
            once: true,
            duration: 700,
            offset: 10,
        });
    },[i18n.language]);

    useEffect(() => {
        loadData().then();
    }, [FetchDetails,i18n.language]);


    return <div>
        <Helmet>
            <title>Portal of Rwandan Laws and Case Laws</title>
            <meta name="description" content="This website is comprised of laws of Rwanda, orders and regulations in force and not in force as well classified in the following ten domains: Fundamental laws, Human rights related laws, Diplomatic and consular laws, Criminal laws, Security laws, Judicial laws, administrative laws, Taxation laws, Civil laws, and Business laws. It also includes selected court decisions rendered by superior courts of Rwanda: Supreme Court, Court of Appeal, High Court and Commercial High Court." />
            <link rel="canonical" href="https://www.amategeko.gov.rw/" />
        </Helmet>
        <Row className={'justify-content-center img-play g-0 px-sm-2'} style={{backgroundImage:`url(${require('./../assets/rwanda-flag-2.gif')})`}}>
            <div className={'col-md-8'}>
                <div className={''}>
                    <h1 className={'fw-bold text-white'} style={{fontFamily:'Raleway-2'}}>{t('portal')}</h1>
                </div>
                <form action="/search/results" method={'get'} className={'mt-5'}>
                    <InputGroup className="mb-1 position-relative overflow-hidden"
                                style={{border:"3px #20603D solid",borderRadius:"40px",backgroundColor:"white"}}>
                        <div className={'position-absolute'} style={{zIndex:10,top:"14px",left:"10px"}}>
                            <img src={require('./../assets/search_icon.png')} style={{width:"30px",height:"30px"}} alt=""/>
                        </div>
                        <Form.Control
                            placeholder={t('search')}
                            aria-label="Search"
                            name={'search'}
                            className={'p-3 border-0 outline-0 ps-5 search-input'}
                            required={true}
                            minLength={3}
                        />
                        <Button variant="secondary" type={'submit'} id="button-addon2" className={'px-5'}
                                style={{borderColor:"#20603D",backgroundColor:"#20603D",borderWidth:"3px",borderRadius:"30px",margin:"0px"}}>
                            {t('search_')}
                        </Button>
                    </InputGroup>
                </form>
                <div className={'text-end me-2 mb-5'}><Link to={'/search'} title={'Search for a word, an expression, or a reference...'} className={'text-decoration-none'} style={{color:"#ffffff",fontWeight:700,fontSize:"14px"}}>{t('advanced_search')}</Link></div>
            </div>
        </Row>

        <div style={{backgroundColor:"#f5f5f5"}}>
            <div className={'pt-5'}>
                <h1 className={'text-navy text-center raleway-2 m-0'}>{t('legal_info')}</h1>
            </div>
            <div className={'mt-5'}>
                <Row className={'justify-content-center g-0'}>
                    {sections.map((v,index)=>{
                        let label;
                        switch (i18n.language){
                            case "en":
                                label = v['name_en'];
                                break;
                            case "fr":
                                label = v['name_fr'];
                                break;
                            case "rw":
                                label = v['name_rw'];
                                break;
                            default:
                                label = "";
                        }
                        switch (index){
                            case 0:
                                return <Col md={2} className={'text-center'}>
                                    <Link to={`/laws/in-force/${v.id}`} title={v.title} className={'access-item rounded-pill d-inline-flex align-items-center justify-content-center'} style={{width:"100px",height:"100px",border:"2px solid #1AA1DE"}}>
                                        <img src={require('./../assets/legislation.png')} style={{width:"40px"}}/>
                                    </Link>
                                    <div className={'mt-2'}><span className={'raleway-2'}>{label}</span></div>
                                </Col>;
                            case 1:
                                return <Col md={2} className={'text-center'}>
                                    <Link to={`/laws/judgement/${v.id}`} title={v.title} className={'access-item rounded-pill d-inline-flex align-items-center justify-content-center'} style={{width:"100px",height:"100px",border:"2px solid #1AA1DE"}}>
                                        <img src={require('./../assets/case_law.png')} style={{width:"40px"}}/>
                                    </Link>
                                    <div className={'mt-2'}><span className={'raleway-2'}>{label}</span></div>
                                </Col>;
                            default:
                                return <Col md={2} className={'text-center'}>
                                    <Link to={`/laws/judgement/${v.id}`} title={v.title} className={'access-item border rounded-pill d-inline-flex align-items-center justify-content-center'} style={{width:"100px",height:"100px"}}>
                                        <i className={'bi bi-book'} style={{fontSize:'30px'}}></i>
                                    </Link>
                                    <div className={'mt-2'}><span>{label}</span></div>
                                </Col>;
                        }
                    })}
                </Row>
            </div>
            <div className={'mt-5 pb-5'}>
                <Row className={'justify-content-center g-0'}>
                    <Col md={8} style={{textAlign:"justify"}}>
                        <p className={'p-sm-2'} style={{wordBreak:"break-all"}}>
                            {t('aboutInfo')}
                        </p>
                    </Col>
                    {/*<Col md={4}>*/}
                    {/*    <img style={{width:"50%"}} src={require('./../assets/map.png')} alt=""/>*/}
                    {/*</Col>*/}
                </Row>
            </div>
        </div>

        <Row className={'justify-content-center g-0'}>
            <div className={'col-md-8 g-0'}>
                <div className={'mt-5'}>
                    <h1 className={'text-navy raleway-2'}>
                        {t('legal_coverage')}
                    </h1>
                </div>
                <Row className={'g-0'}>
                    <div className={'col-md-4 mt-5'}>
                        <div><img src={require('../assets/house.png')} alt=""/></div>
                        <div style={{fontSize:"20px",color:"#00A1DE"}}><b>{t('legislation')}</b></div>
                        <div style={{fontSize:"50px"}}  data-aos="fade-up" data-aos-delay="800" key={`${i18n.language}1`}>
                            <b><CountUp separator={","} start={0} end={data?.count_legislation??0} duration={2} /></b>
                        </div>
                    </div>
                    <div className={'col-md-4 mt-5'}>
                        <div><img src={require('../assets/book.png')} alt=""/></div>
                        <div style={{fontSize:"20px",color:"#C3A505"}}><b>{t('case_laws')}</b></div>
                        <div style={{fontSize:"50px"}}  data-aos="fade-up" data-aos-delay="800" key={`${i18n.language}2`}>
                            <b><CountUp separator={","} start={0} end={data?.count_case_laws??0} duration={2} /></b>
                        </div>
                    </div>
                    <div className={'col-md-4 mt-5'} style={{marginBottom:"60px"}}>
                        <div><img src={require('../assets/documents.png')} alt=""/></div>
                        <div style={{fontSize:"20px",color:"#20603D"}}><b>{t('all_documents')}</b></div>
                        <div style={{fontSize:"50px"}}  data-aos="fade-up" data-aos-delay="800" key={`${i18n.language}3`}>
                            <b><CountUp separator={","} start={0} end={data?.count_documents??0} duration={2} /></b>
                        </div>
                    </div>
                </Row>
            </div>
        </Row>

    </div>;
}

export default connect(mapStateToProps,mapDispatchToProps)(Homepage);
