import * as tocbot from 'tocbot';
import React, {useEffect, useState} from "react";
import content from '../json/content.json'
import {downloadAndSave, highlight, regexIndexOf} from "../store/helpers/common_functions";
import {useTranslation} from "react-i18next";
import {Button, Col, Row, Spinner, Tab, Tabs} from "react-bootstrap";
import {useParams} from "react-router-dom";
import DocumentView from "../pages/DocumentView";
import {connect} from "react-redux";
import {mapStateToProps} from "../store/helpers/mapState";
import {mapDispatchToProps} from "../store/helpers/mapDispatch";
import HtmlDocumentViewer from "./html_document_viewer";
import TextDocumentViewer from "./text_document_viewer";

const TableOfContentViewer = ({FetchDetails,fromJudge}) => {

    const [active, setActive] = useState(0);

    const [list, setList] = useState([]);
    const [path, setPath] = React.useState(null);
    const [pageNum, setPageNum] = React.useState(10);
    const [loading, setLoading] = React.useState(true);
    const [info,setInfo] = React.useState(null);
    const [references, setReferences] = React.useState([]);
    const [found, setFound] = useState(null);

    const [search, setSearch] = useState("");
    const [tab, setTab] = useState(0);
    const {i18n} = useTranslation();
    const [iFileId, setIFileId] = useState(null);
    const [lng, setLng] = useState(i18n.language);

    const {id,fileId} = useParams();

    function getRex(search) {
        search = search.replace(/[.*+?^${}()|[\]\\]/gi, '\\$&'); //https://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex

        return new RegExp(search, 'gi');
    }

    const trim = search.trim();
    const hasSearch = trim.length > 2;
    useEffect(() => {
        if (hasSearch) {
            setList(content.contents.filter(v => getRex(trim).test(v.content[i18n.language])));
        } else {
            setList([]);
        }
    }, [search, i18n.language]);

    const loadDetails = async ()=>{
        setLoading(true);
        setInfo(null);
        setPath(null);
        setFound(null);
        const resp = await FetchDetails(`documents/${id}`);
        if(resp.status){
            setInfo(resp.data);
        }
        setLoading(false);
    }


    const loadReferences = async ()=>{
        const resp = await FetchDetails(`documents/references/${iFileId}`);
        if(resp.status){
            setReferences(resp.data ?? []);
        }
    }

    useEffect(()=>{
        if(iFileId) {
            loadReferences().then();
            loadDocumentView().then();
        }
    },[iFileId]);


    useEffect(()=>{
        setIFileId(fileId);
    },[fileId]);

    const loadDocuments = async () => {

        setLoading(true);
        const resp = await FetchDetails(`documents-files/table?document=${id}`);
        setLoading(false);
        if (resp.status) {
            const find = resp.data.find(v => v.language === i18n.language || v.languages?.split(",")?.filter(v=>v.trim() === i18n.language)?.length>0);
            if (find) {
                setIFileId(find.id);
                // setReferences(find.references?.split(",") ?? []);
            } else {
                const find1 = resp.data.find(v => v.language === "un" || v.languages === "un");
                if (find1) {
                    // setReferences(find1.references?.split(",") ?? []);
                    setIFileId(find1.id);
                } else {
                    if(resp.data.length){
                        setFound(resp.data[0]);
                    }
                    setPath(null);
                }
            }
        }
    }
    const loadDocumentView = async () => {

        setLoading(true);
        const resp = await FetchDetails(`documents-files/${iFileId}`);
        setLoading(false);
        if (resp.status) {
                setPath(resp.data?.file_url);
                // setReferences(find.references?.split(",") ?? []);
        }
    }


    useEffect(() => {
        if (i18n.language && lng !== i18n.language) {
            loadDocuments().then();
            setLng(i18n.language);
        }
    }, [i18n.language]);

    useEffect(()=>{
        if(id){
            loadDetails().then();
        }
    },[id]);

    useEffect(() => {
        if (active === 0) {
            tocbot.init({
                // Where to render the table of contents.
                tocSelector: '.script-toc',
                // Where to grab the headings to build the table of contents.
                contentSelector: '.toc-main',
                // Which headings to grab inside of the contentSelector element.
                headingSelector: 'h1, h2, h3',
                // For headings inside relative or absolute positioned containers within content.
                hasInnerContainers: true,
                // Main class to add to lists.
                linkClass: 'toc-link',
                // Class that gets added when a list should be collapsed.
                isCollapsedClass: 'is-collapsed',
                // Smooth scrolling enabled.
                scrollSmooth: true,
                // Smooth scroll duration.
                scrollSmoothDuration: 420,
                headingsOffset: 40,
                collapseDepth: 0,
            });
            document.body.onscroll = function () {
                const toc = document.querySelector(".js-toc-container")
                if (toc) {
                    const passedScroll = this.scrollY >= 150;
                    toc.style.position = passedScroll ? "fixed" : "absolute";
                    toc.style.top = passedScroll ? "55px" : "auto";
                }

            }
        }
    }, [active, i18n.language])



    const download = async () => {
        setLoading(true);
        await downloadAndSave("files/download", {
            path
        }, "application/pdf");
        setLoading(false);
    }

    return (
       loading ? <div className={'d-flex align-items-center justify-content-center'} style={{minHeight:"82vh"}}>
           <Spinner/>
       </div> : <div className="App" style={{marginTop: "60px"}}>



            {found !== null && <div style={{minHeight:"82vh"}} className={'d-flex align-items-center justify-content-center'}>
                <p>
                    This document is only available in <b>{found.language === 'rw' || found.languages === 'rw' ? 'Kinyarwanda' : found.language === 'fr' || found.languages === 'fr'  ? 'French' : 'English'}</b>
                    <div>Switch to the available language to view this document</div>
                    <div><Button className={'mt-2 bg-greener rounded-pill'} variant={'success'} onClick={e=>{
                        e.preventDefault();
                        setFound(null);
                        i18n.changeLanguage(found.language ?? found.languages).then();
                    }}>Switch</Button></div>
                </p>
            </div>}

            <div className={'d-flex'}>
                <div className={'flex-grow-1'}>
                    <DocumentView references={references} path={path} setPageNum={setPageNum} info={info} fileId={fileId} highlight={highlight}/>
                    {/*{tab === 1 && <TextDocumentViewer id={fileId} language={i18n.language} pageNum={pageNum} highlight={highlight}/>}*/}
                </div>
                {/*{path !== null && <div className={'text-end mb-2 mx-2'}>*/}
                {/*    <Button variant={'success'} className={'success bg-greener'} onClick={() => {*/}
                {/*        setTab(tab => tab >= 1 ? 0 : ++tab);*/}
                {/*    }}>{tab === 0 ? 'Text Viewer' : 'Pdf Viewer'}</Button>*/}
                {/*</div>}*/}
            </div>

            {/*{fromJudge ? <DocumentView references={references} path={path} info={info} setPageNum={setPageNum}/> : <Tabs*/}
            {/*    defaultActiveKey="profile"*/}
            {/*    id="uncontrolled-tab-example"*/}
            {/*    className="bg-greener text-white"*/}
            {/*    style={{backgroundColor:"#20603D"}}*/}
            {/*    onSelect={setTab}*/}
            {/*>*/}

                {/*<Tab eventKey="home" title="Table of Content">*/}
                {/*    <div className={'d-flex'}>*/}
                {/*        <h1 className={'flex-grow-1'}>{content.title[i18n.language]}</h1>*/}
                {/*        <div className={'p-2 text-center'}>*/}
                {/*            {loading ? <Spinner/> : <Button className={'d-flex'} onClick={download}><i*/}
                {/*                className={'bi bi-download me-2'}></i> Download</Button>}*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className={'d-flex'}>*/}
                {/*        <div className={'js-toc-container d-flex my-4 border-top border-bottom border-start'}*/}
                {/*             style={{position: "absolute"}}>*/}
                {/*            <div>*/}
                {/*                <ul className={'main-nav'}>*/}
                {/*                    <li>*/}
                {/*                        <a href="#" onClick={e => {*/}
                {/*                            e.preventDefault();*/}
                {/*                            setActive(0);*/}
                {/*                        }} className={active === 0 ? 'active' : undefined}><i*/}
                {/*                            className={'bi bi-table'}></i></a>*/}
                {/*                    </li>*/}
                {/*                    <li>*/}
                {/*                        <a href="#" onClick={e => {*/}
                {/*                            e.preventDefault();*/}
                {/*                            setActive(1);*/}
                {/*                        }} className={active === 1 ? 'active' : undefined}><i*/}
                {/*                            className={'bi bi-search'}></i></a>*/}
                {/*                    </li>*/}
                {/*                </ul>*/}
                {/*            </div>*/}
                {/*            <div className={'flex-grow-1'}>*/}
                {/*                {active === 0 && <div>*/}
                {/*                    <h5 className={'p-2 m-0 text-start'}>Table of Contents</h5>*/}
                {/*                    <nav className="js-toc script-toc border-start"></nav>*/}
                {/*                </div>}*/}
                {/*                {active === 1 && <div className="border-start p-2">*/}
                {/*                    <div>*/}
                {/*                        <input type="search" className={'form-control full-width'} value={search}*/}
                {/*                               onChange={e => setSearch(e.target.value)}*/}
                {/*                               placeholder={'Search here ...'}/>*/}
                {/*                    </div>*/}
                {/*                    {list.length > 0 ? <div className={'js-toc mt-2'}>*/}
                {/*                        <ol>*/}
                {/*                            {content.contents.map((v, index) => {*/}

                {/*                                const rex = getRex(trim);*/}
                {/*                                let cont = v.content[i18n.language];*/}
                {/*                                let has = rex.test(cont);*/}

                {/*                                const foundIndex = regexIndexOf(cont, rex);*/}

                {/*                                const min = Math.max(0, foundIndex - 50);*/}
                {/*                                const max = Math.min(foundIndex + (trim.length - 1) + 50, cont.length - 1);*/}
                {/*                                const sub = cont.substring(min, max);*/}

                {/*                                return has && <li>*/}
                {/*                                    <a href={`#${index}`}>*/}
                {/*                                        {v.head[i18n.language]}*/}

                {/*                                        {sub.length > 0 &&*/}
                {/*                                            <code style={{fontSize: 11, display: "block"}}*/}
                {/*                                                  dangerouslySetInnerHTML={{__html: `${min <= 0 ? '' : '<b>.....</b>'}${highlight(trim, sub)}${max >= cont.length ? '' : '<b>.....</b>'}`}}/>}*/}
                {/*                                    </a>*/}
                {/*                                </li>*/}
                {/*                            })}*/}
                {/*                        </ol>*/}
                {/*                    </div> : <div className={'my-5 py-5'}>*/}
                {/*                        {hasSearch ? 'No search results found, try different keyword ...' : 'Search results will be displayed here ...'}*/}
                {/*                    </div>}*/}
                {/*                </div>}*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <main className={'flex-grow-1 toc-main my-4 p-3 border text-start'}>*/}

                {/*            {*/}
                {/*                content.contents.map((v, index) => <>*/}
                {/*                    <h2 id={index}>{v.head[i18n.language]}</h2>*/}
                {/*                    <p style={{marginTop: "20px", marginBottom: "20px"}}*/}
                {/*                       dangerouslySetInnerHTML={{__html: highlight(search, v.content[i18n.language])}}>*/}
                {/*                    </p>*/}
                {/*                </>)*/}
                {/*            }*/}

                {/*            <Row className={'justify-content-center'}>*/}
                {/*                <Col md={5}>*/}
                {/*                    <div className={'text-center'} style={{marginTop: "50px"}}>*/}
                {/*                        <code>{content.footer[i18n.language]}</code>*/}
                {/*                    </div>*/}
                {/*                </Col>*/}
                {/*            </Row>*/}

                {/*        </main>*/}
                {/*    </div>*/}
                {/*</Tab>*/}
            {/*</Tabs>}*/}

        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(TableOfContentViewer);