import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {mapStateToProps} from "../store/helpers/mapState";
import {mapDispatchToProps} from "../store/helpers/mapDispatch";
import {Button, Spinner} from "react-bootstrap";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const TextDocumentViewer = ({FetchDetails,SubmitGeneralForm,id,language,page,highlight}) => {

    const [html, setHtml] = useState("");
    const [loading,setLoading] = React.useState(true);
    // const [page, setPage] = useState(1);
    const [inputValue, setInputValue] = useState('')

    const handleClickSearch = () => {
            withReactContent(Swal).fire({
                title: 'Search from here',
                input: 'search',
                inputValue,
                preConfirm: () => {
                    setInputValue(Swal.getInput()?.value || '')
                },
            })
    };


    const loadPath = async ()=>{
        setLoading(true);
       const resp = await SubmitGeneralForm(`documents-files/text/${id}`,{
           language,
           page,
           split:1
       });
        if (resp.status) {
                setHtml(resp.data);
        }
        setLoading(false);
    }

    useEffect(() => {
        if(id) {
            loadPath().then();
        }
    }, [FetchDetails,id,page,language]);




    return <div className={'row w-100'}>
        <div className={'col-md-12'}>
            <div className={'row justify-content-center'}>
                <div className={'col-md-10'}>
                    <div className={'rounded mb-3 p-3'}>
                        <div className={'d-flex justify-content-end'}>
                            <Button variant={'outline-secondary'} onClick={handleClickSearch}><i className={'bi bi-search'}></i></Button>
                        </div>
                        <div className={'mt-2 d-flex align-items-center justify-content-center text-start w-100'}  style={{minHeight:"600px"}}>
                            {
                                loading ? <div className={'d-flex hover h2 align-items-center justify-content-center'}>
                                    <Spinner/>
                                </div> : <div dangerouslySetInnerHTML={{__html:highlight(inputValue,html)}}></div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {/*<div className={'d-flex align-items-center justify-content-center'}>*/}
            {/*    <Button onClick={()=>setPage(v=>v-1)} variant={'outline-secondary'} disabled={page <= 1}> {'<< Previous'}</Button>*/}
            {/*    <div>*/}
            {/*        <span className={'p-2 mx-2 w-10 h-10 border rounded-pill'}>{page}</span>*/}
            {/*    </div>*/}
            {/*    <Button onClick={()=>setPage(v=>v+1)} variant={'outline-secondary'} disabled={page >= pageNum}> {'Next >>'}</Button>*/}
            {/*</div>*/}
        </div>
    </div>
}

export default connect(mapStateToProps,mapDispatchToProps)(TextDocumentViewer);