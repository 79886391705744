/**
 * Create
 * @param state
 */

export const mapStateToProps = (state) => {
  return {
    // user
    loggedUser: state.user.loggedUser,
    errorMessage: state.user.errorMessage,
    sections: state.user.sections,
    documents: state.user.documents,
    loadingDocs: state.user.loadingDocs,
    successMessage: state.user.successMessage
  };
};
