import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {connect} from "react-redux";
import {mapStateToProps} from "../store/helpers/mapState";
import {mapDispatchToProps} from "../store/helpers/mapDispatch";
import {useTranslation} from "react-i18next";



const LawComponent = (props)=>{

    const [laws,setLaws] = useState([]);
    const [loading,setLoading] = useState(false);
    const {i18n} = useTranslation();

    const {FetchDetails,flow,id,track,topValue} = props;
    const loadData = async ()=>{
        let url = flow.url;
        if(id){
            url = url.replace(":id",id)
        }else if(url.includes(":id")){
            return;
        }


        url = `${url}&language=${i18n.language}`;

        if(flow.parentParam){
            const paramArray = Array.isArray(flow.parentParam) ? flow.parentParam : [flow.parentParam];
            for (let param of paramArray){
                let k = 0,ob = {...topValue};
                while (k < param.step && ob !== undefined){
                    ob = ob.parent;
                    k++;
                }
                if(ob) {
                    url = `${url}&${param.key}=${ob.value}`;
                }
            }
        }
        setLoading(true);
        const data = await FetchDetails(url);
        setLoading(false);
        if(data.status){
            setLaws(data.data);
        }
    }

    useEffect(()=>{
        if(flow?.url) {
            loadData().then();
        }
    },[FetchDetails,id,i18n.language]);

    return <div className={'text-start'}>
        {loading ? <div className={'text-center p-2'}>
            <div className="spinner-grow text-secondary" role="status">
                {/*<span className="sr-only">Loading...</span>*/}
            </div>
        </div> : !laws.length ? <div className={'text-center p-2'}>
            <i className={'bi bi-info-circle'}></i>
            <div>No items found !</div>
        </div> : laws.map(v=><LawSingleComponent {...props} flow={flow?.next} topValue={topValue} currentFlow={flow} item={v} track={track}/>)}
    </div>
}

const LawSingleComponent = (props)=>{
    let {item,flow,GetDocuments,currentFlow,track,topValue} = props;
    const [show,setShow] = useState(false);
    const {i18n} = useTranslation();
    let label;
    if(typeof item === 'string'){
        label = item;
    } else if(currentFlow.label){
        label = item[currentFlow.label];
    }else {switch (i18n.language) {
            case "en":
                label = item['name_en'];
                break;
            case "rw":
                label = item['name_rw'];
                break;
            case "fr":
                label = item['name_fr'];
                break;
            default:
                label = "";
                break;
        }
    }

    if(typeof currentFlow.callback === 'function'){
        label = currentFlow.callback(label,i18n.language);
    }

    const hasSub = currentFlow?.next !== undefined;
    let value = typeof item === 'string' ? item : [currentFlow.primaryKey ?? currentFlow.key] ? item[currentFlow.primaryKey ?? currentFlow.key] : item.id;
    if(currentFlow.castNumeric){
        value = Number(value);
    }
    let newTrack = {
        parent:track,
        value,
        label:currentFlow.label ?? "id",
        key:currentFlow.key ?? "id"
    };



        if(typeof topValue === 'object'){
        topValue = {
            value,
            key:newTrack.key,
            parent:{
                ...topValue
            }
        }
    }else{
        topValue = {
            value,
            key:newTrack.key
        }
    }


    return <div className={'my-2'}>
        <Link to={item.href} className={'fw-bold text-decoration-none'} style={{color:"#63866F"}} onClick={hasSub ? (event)=>{
            event.preventDefault();
            setShow(show=>!show);
        } : (event)=>{
            event.preventDefault();
            let append = [];
            let next = newTrack;
            while (next) {
                append = [...append,`${next.key}=${next.value}`];
                next = next.parent;
            }
            append = [...append,`language=${i18n.language}`];
                GetDocuments(append.join("&"),true);

        }}>{hasSub ? <i className={show ? "bi bi-dash-square" : "bi bi-plus-square"}></i> : <i className="bi bi-link-45deg"></i>} <span className={'ms-2'}>{label}</span> {item.count>0 && <span>({item.count})</span>}</Link>
        <div className={'ms-4'}>{hasSub && show && <LawComponent flow={flow} {...props} id={value} topValue={topValue} track={newTrack}/>}</div>
    </div>;
}

export default connect(mapStateToProps,mapDispatchToProps)(LawComponent);
