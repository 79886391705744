import LawComponent from "../components/LawComponent";
import {scholarFlow} from "../constants";
import {useSearchParams} from "react-router-dom";

function ScholarMaterials() {

    const [searchParams] = useSearchParams();
    const activeChildQuery = searchParams.get('child');
    const activeGChildQuery = searchParams.get('g-child');

    const id = activeGChildQuery ?? activeChildQuery;
    return <div>
        <LawComponent flow={scholarFlow} topValue={{
            value:id,
            key:'section'
        }} id={id} track={{value:id,label:'section',key:'section'}}/>
    </div>
}

export default ScholarMaterials;
