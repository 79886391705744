import {useState} from "react";
import {useTranslation} from "react-i18next";

const LegalNotices = () => {
    const [show1, setShow1] = useState(true);
    const [show2, setShow2] = useState(true);
    const [show3, setShow3] = useState(true);
    const [show4, setShow4] = useState(true);
    const {t} = useTranslation();
    return <div>
        <div className={'shadow-sm m-3 p-3 rounded text-start'}>
            <div className={'d-flex'}>
                <h2 className={'flex-grow-1'}>{t('public_notices_title')}</h2>
                <a href={'#'} style={{color:"#63866F"}} onClick={()=>{
                    setShow4(v=>!v);
                }}>
                    <i className={`bi bi-caret-${show4 ? 'down' : 'left'}-fill`} style={{fontSize:'30px'}}></i>
                </a>
            </div>
            {show4 && <p style={{textAlign:"justify"}} dangerouslySetInnerHTML={{__html:t('public_notices')}}>
            </p>}
        </div>
      <div className={'shadow-sm m-3 p-3 rounded text-start'}>
          <div className={'d-flex'}>
              <h2 className={'flex-grow-1'}>{t('legal_notices')}</h2>
              <a href={'#'} style={{color:"#63866F"}} onClick={()=>{
                  setShow1(v=>!v);
              }}>
                  <i className={`bi bi-caret-${show1 ? 'down' : 'left'}-fill`} style={{fontSize:'30px'}}></i>
              </a>
          </div>
          {show1 && <p style={{textAlign:"justify"}} dangerouslySetInnerHTML={{__html:t('legal_content')}}>
          </p>}
      </div>

      <div className={'shadow-sm m-3 p-3 rounded text-start'}>
          <div className={'d-flex'}>
              <h2 className={'flex-grow-1'}>{t('privacy_policy')}</h2>
              <a href={'#'} style={{color:"#63866F"}} onClick={()=>{
                  setShow2(v=>!v);
              }}>
                  <i className={`bi bi-caret-${show2 ? 'down' : 'left'}-fill`} style={{fontSize:'30px'}}></i>
              </a>
          </div>
          {show2 && <p style={{textAlign:"justify"}} dangerouslySetInnerHTML={{__html:t('privacy_content')}}>
          </p>}
      </div>

      <div className={'shadow-sm m-3 p-3 rounded text-start'}>
          <div className={'d-flex'}>
              <h2 className={'flex-grow-1'}>{t('service_terms')}</h2>
              <a href={'#'} style={{color:"#63866F"}} onClick={()=>{
                  setShow3(v=>!v);
              }}>
                  <i className={`bi bi-caret-${show3 ? 'down' : 'left'}-fill`} style={{fontSize:'30px'}}></i>
              </a>
          </div>
          {show3 && <p style={{textAlign:"justify"}} dangerouslySetInnerHTML={{__html:t('terms_content')}}>
          </p>}
      </div>


  </div>
}

export default LegalNotices;