const LoadingSpinner = () => {
  return <div style={{minHeight: "50vh"}} className={'d-flex align-items-center justify-content-center'}>
      <div className={'card text-center'}>
          <div className={'card-body shadow-sm'}>
              <div className="spinner-border d-inline-block" role="status">
                  <span className="sr-only"></span>
              </div>
              <h3>Loading ......</h3>
          </div>
      </div>
  </div>;
}

export default LoadingSpinner;
