import 'bootstrap/dist/css/bootstrap.min.css';
import {Button, Card, Spinner} from 'react-bootstrap';

import {DocumentLoadEvent, SpecialZoomLevel, Worker} from '@react-pdf-viewer/core';
// Import the main component
import { Viewer } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import { getFilePlugin } from '@react-pdf-viewer/get-file';

// Import plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { openPlugin } from '@react-pdf-viewer/open';
import { bookmarkPlugin } from '@react-pdf-viewer/bookmark';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';
// Import styles
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';
import '@react-pdf-viewer/open/lib/styles/index.css';
import '@react-pdf-viewer/bookmark/lib/styles/index.css';
import SearchSidebar from './SearchSidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft, faClose,
  faDownload,
  faExpand,
  faImages, faLanguage,
  faLink, faMagnifyingGlassMinus, faMagnifyingGlassPlus,
  faPrint,
  faSearch,
  faTableList
} from '@fortawesome/free-solid-svg-icons';
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {errorToast} from "../helpers/Notification";
import {PageLayout} from "@react-pdf-viewer/core";
import {LANGUAGES} from "../constants";
import TextDocumentViewer from "./text_document_viewer";
// import './App.css';
const useWindowSize = () => {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
  useEffect(() => {
    const handleResize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return size;
};
function CustomDocumentViewer({fileUrl,info,references,FetchDetails,highlight,fileId}) {

  const openPluginInstance = openPlugin();
  const bookmarkPluginInstance = bookmarkPlugin();
  const thumbnailPluginInstance = thumbnailPlugin();
  const [loadingReferences, setLoadingReferences] = useState(false);
  const [language, setLanguage] = useState(null);

  const { Open } = openPluginInstance;
  const { Bookmarks } = bookmarkPluginInstance;
  const { Thumbnails } = thumbnailPluginInstance;
  const navigate = useNavigate();

  const [width] = useWindowSize();

  const loadReference = async (v)=>{
    setLoadingReferences(true);
    const resp = await FetchDetails(`documents/reference?case_no=${v}`);
    setLoadingReferences(false);
    if(resp.status){
      // console.log(resp.data);
      navigate(`/view/toc/doc/${resp.data.id}`);
    }else{
      errorToast("This reference was not found, contact admin !");
    }
  }

  const getFilePluginInstance = getFilePlugin({
    fileNameGenerator: (file) => {
      // `file.name` is the URL of opened file
      console.log(info);
      const fileName = file?.name?.substring(file.name?.lastIndexOf('/') + 1);
      return `${info?.name ?? fileName}.pdf`;
    },
  });
  const { Download } = getFilePluginInstance;

  console.log("width",width);
  const renderToolbar2 = (Toolbar) => {
      return (
          <>
            {(toolbarSlot) => {
              return <div style={{display: 'flex', justifyContent: 'space-between'}}>
                {toolbarSlot.openFileButton}
                {toolbarSlot.downloadButton}
              </div>
            }}
          </>
      );
  }
  const renderToolbar = (Toolbar) => {
    return (
        <Toolbar>
          {(toolbarSlot) => {
            const {
              // Download,
              EnterFullScreen,
              Print,
              Zoom,
              ZoomIn,
              ZoomOut,
            } = toolbarSlot;


            return (
                <div className="rpv-toolbar text-white" role="toolbar" aria-orientation="horizontal" style={{backgroundColor:"#63866F",padding:"15px"}}>
                  <div className="rpv-toolbar__left">
                    <div className={'d-flex align-items-center justify-content-center'} style={{ width: "3rem", height: "3rem" }}>
                      <Button variant="link" size="sm" className={'text-white'} onClick={()=>navigate(-1)}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                      </Button>
                    </div>
                    {/*<div className="rpv-core__display--hidden rpv-core__display--block-small" style={{ marginLeft: "1rem" }}>*/}
                    {/*  <div className="rpv-toolbar__item">*/}
                    {/*    Pages*/}
                    {/*  </div>*/}
                    {/*</div>*/}

                  </div>
                  <div className="rpv-toolbar__center text-white">
                    <div>
                      {info?.name}
                    </div>
                  </div>
                  <div className="rpv-toolbar__right">
                    {/*<div className="rpv-core__display--hidden rpv-core__display--block-small">*/}
                    {/*  <div className="rpv-toolbar__item">*/}
                    {/*    <GoToPreviousPage />*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                    {/*<div className="rpv-toolbar__item">*/}
                    {/*  <CurrentPageInput />{' '}*/}
                    {/*  <span className="rpv-toolbar__label">*/}
                    {/*    / <NumberOfPages />*/}
                    {/*  </span>*/}
                    {/*</div>*/}
                    {/*<div className="rpv-core__display--hidden rpv-core__display--block-small">*/}
                    {/*  <div className="rpv-toolbar__item">*/}
                    {/*    <GoToNextPage />*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                    <div className="rpv-toolbar__center">
                      <div className="rpv-toolbar__item">
                        <ZoomOut >
                          {(props) => (
                              <Button variant="link" size="sm" className={'text-white'} onClick={props.onClick}>
                                <FontAwesomeIcon icon={faMagnifyingGlassMinus} />
                              </Button>
                          )}
                        </ZoomOut>
                      </div>
                      <div className="rpv-core__display--hidden rpv-core__display--block-small">
                        <div className="rpv-toolbar__item top-item">
                          <Zoom className={'text-white'}/>
                        </div>
                      </div>
                      <div className="rpv-toolbar__item">
                        <ZoomIn>
                          {(props) => (
                              <Button variant="link" size="sm" className={'text-white'} onClick={props.onClick}>
                                <FontAwesomeIcon icon={faMagnifyingGlassPlus} />
                              </Button>
                          )}
                        </ZoomIn>
                      </div>
                      <div className="rpv-toolbar__item text-white">
                        <EnterFullScreen>
                          {(props) => (
                              <Button variant="link" size="sm" className={'text-white'} onClick={props.onClick}>
                                <FontAwesomeIcon icon={faExpand} />
                              </Button>
                          )}
                        </EnterFullScreen>
                      </div>
                    </div>
                    <div className="rpv-core__display--hidden rpv-core__display--block-medium">
                      <div className="rpv-toolbar__item">
                        <Download>
                          {
                            (props) => (
                                <Button variant="link" size="sm" className={'text-white'} onClick={props.onClick}>
                                  <FontAwesomeIcon icon={faDownload} />
                                </Button>
                            )
                          }
                        </Download>
                      </div>
                    </div>
                    <div className="rpv-core__display--hidden rpv-core__display--block-medium pe-2">
                      <div className="rpv-toolbar__item">
                        <Print>
                          {(props) => (
                              <Button variant="link" size="sm" className={'text-white'} onClick={props.onClick}>
                                <FontAwesomeIcon icon={faPrint} />
                              </Button>
                          )}
                        </Print>
                      </div>
                    </div>
                  </div>
                </div>
            );
          }}
        </Toolbar>
    );
  };

  const defaultLayoutPluginInstance = width < 768 ? defaultLayoutPlugin() : defaultLayoutPlugin({
    setInitialTab: () => Promise.resolve(0),
    renderToolbar,
    sidebarTabs: (defaultTabs) => [
      {
        content: (
            <SearchSidebar
                searchPluginInstance={
                  defaultLayoutPluginInstance.toolbarPluginInstance.searchPluginInstance
                }
            />
        ),
        icon: <div>
          <FontAwesomeIcon icon={faSearch} />
          <span className={'ms-1'}>Search</span>
        </div>,
        title: 'Search',
      },
      {
        content: <div style={{ textAlign: 'center', width: '100%', marginTop: "7px",position:"relative" }}>
          {loadingReferences && <div className={'position-absolute top-0 left-0 w-100 h-100 d-flex align-items-center justify-content-center'} style={{
            background:"rgba(0,0,0,.2)",
            zIndex:12
          }}>
            <Spinner/>
          </div>}
          <ul className={'list-group border-0'}>
            {
              references.map(v=><li className={'list-group-item border-0 border-bottom text-start'}><a onClick={e=>{
                e.preventDefault();

                if(v.id>0 && v.document?.file_id) {
                  navigate(`/view/toc/doc/${v.id}/${v.document?.file_id}`);
                }else {
                  navigate(`/view/doc_case/${encodeURIComponent(v.code)}`);
                }
                // loadReference(v.code).then();
              }} href={`${process.env.REACT_APP_BACKEND_URL}v1/site/documents/reference?case_no=${v.code}`} style={{
                color:"#63866F"
              }} className={'text-decoration-none'}>
                {v.code}
                <div>{v.document?.name}</div>
                <div>{v.document?.category?.name_en??""} - {v.document?.sub_category?.name_en??""} {v.document?.collection?.name ? '-' : ''} {v.document?.collection?.name??""} {v.document?.volume?.name ? '-' : ''} {v.document?.volume?.name??""}</div>
              </a></li>)
            }
          </ul>
        </div>,
        icon: <div>
          <FontAwesomeIcon icon={faLink} />
          <span className={'ms-1'}>References</span>
        </div>,
        title: 'References',
      },
      {
        content: <Thumbnails />,
        icon: <div>
          <FontAwesomeIcon icon={faImages} />
          <span className={'ms-1'}>Thumbnails</span>
        </div>,
        title: 'Thumbnails',
      },
      {
        content: <Bookmarks />,
        icon: <div>
          <FontAwesomeIcon icon={faTableList} />
          <span className={'ms-1'}>Bookmarks</span>
        </div>,
        title: 'Bookmarks',
      },
      // {
      //   content: <div className={'w-100'}>
      //     <ul className={'list-group border-0'}>
      //       {
      //         LANGUAGES.map(v=><li className={'list-group-item border-0 border-bottom text-start'}>
      //           <a onClick={e=>{
      //             e.preventDefault();
      //             setLanguage(v.code);
      //           }} href={`/lang/${v.code}`} style={{
      //             color:"#63866F"
      //           }} className={'text-decoration-none'}>
      //             {v.label}
      //           </a>
      //         </li>)
      //       }
      //     </ul>
      //     <div className={'mt-3'}>
      //       <Button size={'sm'} variant={'outline-danger'} onClick={()=>{
      //         setLanguage(null);
      //       }}>
      //
      //         <FontAwesomeIcon icon={faClose} />
      //         <span className={'ms-1'}>Close</span>
      //       </Button>
      //     </div>
      //   </div>,
      //   icon: <div>
      //     <FontAwesomeIcon icon={faLanguage} />
      //     <span className={'ms-1'}>Language Specific</span>
      //   </div>,
      //   title: 'Language Specific',
      // },
    ],
  });








  const pageLayout: PageLayout = {
    tranformSize: ({ size }) => ({ height: size.height + 30, width: size.width + 30 }),
  };
  const handleDocumentLoad = (e: DocumentLoadEvent) => {
    const { activateTab } = defaultLayoutPluginInstance;
    // Activate the bookmark tab
    activateTab(0);
  };

  return (
    <>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
            <div style={{ height: '640px' }}>
              <Viewer
                  key={width}
                fileUrl={fileUrl}
                pageLayout={pageLayout}
                defaultScale={SpecialZoomLevel.PageWidth}
                renderPage={language ? (props)=>{
                  props.markRendered(props.pageIndex);
                  const {height,width} = props;
                  return language ? <div style={{height:`${height}px`,width:`${width}px`,overflowY:'scroll'}}>
                    <TextDocumentViewer language={language} page={props.pageIndex+1} id={fileId} highlight={highlight}/>
                  </div> : props.canvasLayer.children;
                } : undefined}
                plugins={ [
                  defaultLayoutPluginInstance,
                  openPluginInstance,
                  bookmarkPluginInstance,
                  thumbnailPluginInstance,
                  getFilePluginInstance
                ]}
                onDocumentLoad={handleDocumentLoad}
              />
            </div>
          </Worker>
    </>
  );
}

export default CustomDocumentViewer;
