import Container from "react-bootstrap/Container";
import {useTranslation} from "react-i18next";

function FooterNavigation() {
    const {t} = useTranslation();
    return <div>
        {/*<div className={'d-flex justify-content-center mt-5'}>*/}
        {/*    <a href="https://facebook.com" className={'me-4'}>*/}
        {/*        <i className={'bi bi-facebook'} style={{fontSize:"40px"}}></i>*/}
        {/*    </a>*/}
        {/*    <a href="https://twitter.com" className={'me-4'}>*/}
        {/*        <i className={'bi bi-twitter'} style={{fontSize:"40px"}}></i>*/}
        {/*    </a>*/}
        {/*    <a href="https://youtube.com" className={'me-4'}>*/}
        {/*        <i className={'bi bi-youtube'} style={{fontSize:"40px"}}></i>*/}
        {/*    </a>*/}
        {/*    <a href="https://instagram.com" className={'me-4'}>*/}
        {/*        <i className={'bi bi-instagram'} style={{fontSize:"40px"}}></i>*/}
        {/*    </a>*/}
        {/*    <a href="https://linkedin.com" className={'me-4'}>*/}
        {/*        <i className={'bi bi-linkedin'} style={{fontSize:"40px"}}></i>*/}
        {/*    </a>*/}
        {/*</div>*/}
        <div className={'p-3 text-white mt-3'} style={{backgroundColor:"#63866f"}}>
            <Container className={'d-flex justify-content-center'}>
                <div className={'text-start'}>
                    &copy;{new Date().getFullYear()} {t('government')}
                </div>
                {/*<div>*/}
                {/*    {t('privacy_policy')}*/}
                {/*</div>*/}
            </Container>
        </div>
    </div>
}

export default FooterNavigation;
