const NoResultsFound = () => {
  return <div style={{minHeight: "50vh"}} className={'d-flex align-items-center justify-content-center'}>
    <div>
      {/*<i className={'bi bi-info-circle-fill text-danger'} style={{fontSize:"50px"}}></i>*/}
      <img src={require('./../assets/no-result-found.png')} alt=""/>
      {/*<h3 className={'text-danger'}>No Results found ...!</h3>*/}
    </div>
  </div>
}

export default NoResultsFound;
