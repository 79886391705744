import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {mapStateToProps} from "../store/helpers/mapState";
import {connect} from "react-redux";
import {mapDispatchToProps} from "../store/helpers/mapDispatch";
import {useEffect, useState} from "react";
import AOS from "aos";
import "./../../node_modules/aos/dist/aos";
import "./../../node_modules/aos/dist/aos.css";
import CountUp from "react-countup";

function Homepage({sections,FetchDetails}) {
    const {t,i18n} = useTranslation();
    const [data, setData] = useState();

    const loadData = async () =>{
       const resp = await FetchDetails(`statistics/counts?language=${i18n.language}`);
       if(resp.status){
           setData(resp.data);
       }
    }
    useEffect(() => {
        AOS.init({
            startEvent: "DOMContentLoaded",
            disable: function () {
                var maxWidth = 996;
                return window.innerWidth < maxWidth;
            },
            throttleDelay: 10,
            once: true,
            duration: 700,
            offset: 10,
        });
    },[i18n.language]);

    useEffect(() => {
        loadData().then();
    }, [FetchDetails,i18n.language]);


    return <div>
        <Row className={'justify-content-center'}>
            <div className={'col-md-8'}>
                <div className={'mt-5'}>
                    <h1 className={'fw-bold'}>{t('portal')}</h1>
                </div>
                <form action="/search/results" method={'get'} className={'mt-5'}>
                    <InputGroup className="mb-1 position-relative rounded-pill overflow-hidden"
                                style={{border:"3px #63866F solid"}}>
                        <div className={'position-absolute'} style={{zIndex:10,top:"14px",left:"10px"}}>
                            <img src={require('./../assets/search_icon.png')} style={{width:"30px",height:"30px"}} alt=""/>
                        </div>
                        <Form.Control
                            placeholder={t('search')}
                            aria-label="Search"
                            name={'search'}
                            aria-describedby="basic-addon2"
                            className={'p-3 border-0 outline-0 ps-5'}
                            required={true}
                            minLength={3}
                        />
                        <Button variant="secondary" type={'submit'} id="button-addon2" className={'rounded-pill px-5'}
                                style={{borderColor:"#63866F",backgroundColor:"#63866F",borderWidth:"3px"}}>
                            {t('search_')}
                        </Button>
                    </InputGroup>
                </form>
                <div className={'text-end me-2'}><Link to={'/search'} className={'text-decoration-none'} style={{color:"#1AA1DE",fontWeight:700,fontSize:"14px"}}>{t('advanced_search')}</Link></div>
            </div>
        </Row>
        <div className={'mt-5'}>
            <h1 className={'text-navy text-center'}>{t('legal_info')}</h1>
        </div>
        <div className={'mt-5'}>
            <Row className={'justify-content-center'}>
                {sections.map((v,index)=>{
                    let label;
                    switch (i18n.language){
                        case "en":
                            label = v['name_en'];
                            break;
                        case "fr":
                            label = v['name_fr'];
                            break;
                        case "rw":
                            label = v['name_rw'];
                            break;
                        default:
                            label = "";
                    }
                    switch (index){
                        case 0:
                            return <Col md={2} className={'text-center'}>
                                <Link to={`/laws/in-force/${v.id}`} className={'access-item rounded-pill d-inline-flex align-items-center justify-content-center'} style={{width:"100px",height:"100px",border:"2px solid #1AA1DE"}}>
                                    <img src={require('./../assets/legislation.png')} style={{width:"40px"}}/>
                                </Link>
                                <div className={'mt-2'}><span>{label}</span></div>
                            </Col>;
                        case 1:
                            return <Col md={2} className={'text-center'}>
                                <Link to={`/laws/judgement/${v.id}`} className={'access-item rounded-pill d-inline-flex align-items-center justify-content-center'} style={{width:"100px",height:"100px",border:"2px solid #1AA1DE"}}>
                                    <img src={require('./../assets/case_law.png')} style={{width:"40px"}}/>
                                </Link>
                                <div className={'mt-2'}><span>{label}</span></div>
                            </Col>;
                        default:
                            return <Col md={2} className={'text-center'}>
                                <Link to={`/laws/judgement/${v.id}`} className={'access-item border rounded-pill d-inline-flex align-items-center justify-content-center'} style={{width:"100px",height:"100px"}}>
                                    <i className={'bi bi-book'} style={{fontSize:'30px'}}></i>
                                </Link>
                                <div className={'mt-2'}><span>{label}</span></div>
                            </Col>;
                    }
                })}
            </Row>
        </div>
        <div className={'mt-5'}>
            <Row className={'justify-content-center'}>
                <Col md={8} style={{textAlign:"justify"}}>
                    <p>
                        {t('aboutInfo')}
                    </p>
                </Col>
                {/*<Col md={4}>*/}
                {/*    <img style={{width:"50%"}} src={require('./../assets/map.png')} alt=""/>*/}
                {/*</Col>*/}
            </Row>
        </div>
        <Row className={'justify-content-center'}>
            <div className={'col-md-8'}>
                <div className={'mt-5'}>
                    <h1 className={'text-navy'}>
                        {t('legal_coverage')}
                    </h1>
                </div>
                <div className={'row'}>
                    <div className={'col-md-4 mt-5'}>
                        <div style={{fontSize:"20px"}}><b>{t('legislation')}</b></div>
                        <div style={{fontSize:"50px"}}  data-aos="fade-up" data-aos-delay="800" key={`${i18n.language}1`}>
                            <b><CountUp separator={","} start={0} end={data?.count_legislation??0} duration={2} /></b>
                        </div>
                    </div>
                    <div className={'col-md-4 mt-5'}>
                        <div style={{fontSize:"20px"}}><b>{t('case_laws')}</b></div>
                        <div style={{fontSize:"50px"}}  data-aos="fade-up" data-aos-delay="800" key={`${i18n.language}2`}>
                            <b><CountUp separator={","} start={0} end={data?.count_case_laws??0} duration={2} /></b>
                        </div>
                    </div>
                    <div className={'col-md-4 mt-5'} style={{marginBottom:"60px"}}>
                        <div style={{fontSize:"20px"}}><b>{t('all_documents')}</b></div>
                        <div style={{fontSize:"50px"}}  data-aos="fade-up" data-aos-delay="800" key={`${i18n.language}3`}>
                            <b><CountUp separator={","} start={0} end={data?.count_documents??0} duration={2} /></b>
                        </div>
                    </div>
                </div>
            </div>
        </Row>

    </div>;
}

export default connect(mapStateToProps,mapDispatchToProps)(Homepage);
