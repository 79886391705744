import { useEffect, useRef } from 'react';

export default function PdfViewerComponent(props) {
    const containerRef = useRef(null);
    const item = {
        type: "custom",
        id: "my-button",
        node: <span>Hello</span>,
        onPress: (event) => {
            alert("hello from my button");
        }
    };
    useEffect(() => {
        const container = containerRef.current;
        let PSPDFKit;

        (async function () {
            PSPDFKit = await import('pspdfkit');
            PSPDFKit.unload(container);
            const toolbarItems = PSPDFKit.defaultToolbarItems;

            // const headers = new Headers();
            // headers.set("Access-Control-Allow-Origin", `*`);
            // headers.set("Authorization",`Bearer ${localStorage.getItem("token")}`);

            // // Fetch the PDF and read the response as an `ArrayBuffer`.
            // const pdfResponse = await axios.post(`files/download`, {
            //     path:props.document
            // },{ headers,responseType:"blob" });
            // console.log(pdfResponse.data);
            //
            // const file = new Blob([pdfResponse.data], {
            //     type: "application/pdf",
            // });
            PSPDFKit.load({
                // Container where PSPDFKit should be mounted.
                // licenseKey:'F8qi62XJgalxbPL5_T-PepppcZ-BUcOiXCDQwsW-dGlGF65pV8BPt1ab8ap-r0ys4HXtNN8COqt-C4zwhjBs4Lz7fBs9EM5kJe_yQ7vUvgEEFDZAgkKJB66TcWOAsxZ1HWEo27SyzbqInugkHWfJJ94al0AKAworFmNjrvXZ_AD9uetG5bzsJGp2ygpGgng5y_p-ogOCNKoo8ks_5ab7bvMDHAvtl04AaAWZYZrrlMj3L2pbS4hZoLA77ipKzNs7V1svB6pXC3zEwGhFd6-S76LaNmfIzfSAVFodcazX_n16Nz48vaFFFJSRjutaVviiFYnSZmlyJ8aDZfCqKTz024S9OvRQEy53XQWPwSXeLeDZe1NYAKqDew1JndwxDen9vm9MpPISPPiR30_C9ethNRNyOqbchHwGLOJsIEfirj7pYJOkpa33EGb5KiNJMdhBSk2sQGD42SawG_h8W1LUzy7oGL4iFREbA7K8kjn2d3hmRMlCzkDBXfQJj6aNIeOHU1U_Vc4Ij-98gCzuNlcO79EiHR44QLgTlDZrRLKjgReEub0HmhbS7Zpi-iz8zmjBViVcu-f1w_HbCGZoYhE6fMxJLX6FuWLEG28_vei6Z6r30E32HpmsfG7OSEWQtElB18Y7fgWTpIaRs4SarwWhag==\n',
                container,
                // The document to open.
                document: props.document,
                styleSheets: [
                    // hosted CSS file
                    `${window.location.protocol}//${window.location.host}/pdfkit.css`
                ],
                // toolbarItems:toolbarItems.filter(v=>v.type === 'search' || v.type === 'export-pdf' || v.type === 'link'),
                // Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
                baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
            });
        })();

        return () => PSPDFKit && PSPDFKit.unload(container);
    }, [props.document]);

    return (
        <div
            ref={containerRef}
            style={{ width: '100%', height: '100vh' }}
        />
    );
}