export const LANGUAGES = [
    {label: "Kinyarwanda", code: "rw"},
    {label: "English", code: "en"},
    {label: "French", code: "fr"},
];
const callback = (item,lng)=>{
    const find = monthList.find(v=>v.id === Number(item));

    if(!find){
        return "";
    }

    return lng === 'fr' ? find.name_fr : lng === 'rw' ? find.name_rw : find.name_en;
}
export const forceFlow = {
    url: 'dropdowns/categories?section=:id',
    key: 'category',
    primaryKey: 'value',
    label:'label',
    next: {
        url: 'dropdowns/sub-categories?category=:id',
        key: 'sub_category',
        primaryKey: 'value',
        label:'label',
        parentParam:{
            key:'section',
            step:1
        },
        next: {
            label: 'value',
            primaryKey: 'value',
            key: 'year',
            url: 'dropdowns/years?sub_category=:id',
            parentParam:[
                {
                    key:'category',
                    step:1
                },
                {
                    key:'section',
                    step:2
                }
            ],
            next: {
                label: 'value',
                primaryKey: 'value',
                key: 'month',
                url: 'dropdowns/months?year=:id',
                parentParam:[{
                    key:'sub_category',
                    step:1
                },
                    {
                        key:'category',
                        step:2
                    },
                    {
                        key:'section',
                        step:3
                    }
                    ],
                callback,
                next: {
                    label: 'value',
                    primaryKey: 'value',
                    key: 'date',
                    url: 'dropdowns/dates?month=:id',
                    parentParam:[
                        {
                        key:'year',
                        step:1
                    },
                        {
                        key:'sub_category',
                        step:2
                    },
                        {
                        key:'category',
                        step:3
                    },
                        {
                        key:'section',
                        step:4
                    }
                    ]
                }
            }
        }
    }
}
export const judgeFlow = {
    url: 'dropdowns/collections?section=:id',
    label: 'label',
    key:'collection',
    primaryKey: 'value',
    next: {
        url: 'dropdowns/years?collection=:id',
        label: 'value',
        key: 'year',
        primaryKey: 'value',
        parentParam:{
    key:'section',
        step:1
},
        next: {
            url: 'dropdowns/months?year=:id',
            label: 'value',
            key: 'month',
            callback,
            primaryKey: 'value',
            parentParam:[{
                key:'collection',
                step:1
            },{
                key:'section',
                step:2
            },],
            next: {
                label: 'value',
                key: 'date',
                primaryKey: 'value',
                url: 'dropdowns/dates?month=:id',
                parentParam:[
                    {
                        key:'year',
                        step:1
                    },
                    {
                        key:'collection',
                        step:2
                    },
                    {
                        key:'section',
                        step:3
                    }
                ]
            }
        }
    }
}
export const flow = {
    url: 'dropdowns/years?section=:id',
    label: 'value',
    primaryKey: 'value',
    key: 'year',
    next:{
        url:'dropdowns/volumes?year=:id',
        key:'volume',
        primaryKey:'value',
        label:'label',
        parentParam:{
            key:'section',
            step:1
        },
        final:true
    }
}
export const precedenceFlow = {
    url: 'dropdowns/collections?section=:id',
    label: 'label',
    key: 'collection',
    primaryKey: 'value',
    next: {
        url: 'dropdowns/years?collection=:id',
        label: 'value',
        primaryKey: 'value',
        key: 'year',
        parentParam:{
            key: 'section',
            step: 1
        },
        next: {
            url: 'dropdowns/months?year=:id',
            label: 'value',
            key: 'month',
            callback,
            primaryKey: 'value',
            parentParam: [{
                key: 'collection',
                step: 1
            }, {
                key: 'section',
                step: 2
            },],
            next: {
                label: 'value',
                key: 'date',
                primaryKey: 'value',
                url: 'dropdowns/dates?month=:id',
                parentParam: [
                    {
                        key: 'year',
                        step: 1
                    },
                    {
                        key: 'collection',
                        step: 2
                    },
                    {
                        key: 'section',
                        step: 3
                    }
                ]
            }
        }
    }
}
export const scholarFlow = {
    url: 'dropdowns/years?section=:id',
    label: 'value',
    primaryKey: 'value',
    key: 'year',
    next: {
        url: 'dropdowns/months?year=:id',
        label: 'value',
        key: 'month',
        callback,
        primaryKey: 'value',
        parentParam:[{
            key:'section',
            step:1
        }],
        next: {
            label: 'value',
            key: 'date',
            primaryKey: 'value',
            url: 'dropdowns/dates?month=:id',
            parentParam:[
                {
                    key:'year',
                    step:1
                },
                {
                    key:'section',
                    step:2
                },
            ]
        }
    }
}

export const monthList = [
    {name_en: "January",name_fr:"Janvier",name_rw:"Mutarama", id: 1},
    {name_en: "February",name_fr:"Février",name_rw:"Gashyantare", id: 2},
    {name_en: "March",name_fr:"Mars",name_rw:"Werurwe", id: 3},
    {name_en: "April",name_fr:"Avril",name_rw:"Mata", id: 4},
    {name_en: "May",name_fr:"Mai",name_rw:"Gicurasi", id: 5},
    {name_en: "June",name_fr:"Juin",name_rw:"Kamena", id: 6},
    {name_en: "July",name_fr:"Juillet",name_rw:"Nyakanga", id: 7},
    {name_en: "August",name_fr:"Août",name_rw:"Kanama", id: 8},
    {name_en: "September",name_fr:"Septembre",name_rw:"Nzeri", id: 9},
    {name_en: "October",name_fr:"Octobre",name_rw:"Ukwakira", id: 10},
    {name_en: "November",name_fr:"Novembre",name_rw:"Ugushyingo", id: 11},
    {name_en: "December",name_fr:"Décembre",name_rw:"Ukuboza", id: 12},
];
