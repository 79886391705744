import moment from "moment";
import axios from "axios";
import {saveAs} from "file-saver";

export const base64ToArrayBuffer = (base64)=> {
    const binaryString = atob(base64);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}

export function highlight(search, text) {
    search = search.replace(/[.*+?^${}()|[\]\\]/gi, '\\$&'); //https://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex

    const re = new RegExp(search, 'gi');

    if (search.length > 0)
        return text.replace(re, `<mark>$&</mark>`);
    else return text;
}

export const errorInterceptor = async (error) => {
    // whatever you want to do with the error
    if (error?.response?.status === 401 && localStorage.getItem("token")) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        window.location.reload();
        throw error?.response;
    }

    throw error?.response;
}

export const getQuery = (key) => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    return params.get(key);
}

export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});

function blobToBase64(blob) {
    return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
}

export function regexIndexOf(string, regex, startpos) {
    var indexOf = string.substring(startpos || 0).search(regex);
    return (indexOf >= 0) ? (indexOf + (startpos || 0)) : indexOf;
}

function regexLastIndexOf(string, regex, startpos) {
    regex = (regex.global) ? regex : new RegExp(regex.source, "g" + (regex.ignoreCase ? "i" : "") + (regex.multiLine ? "m" : ""));
    if(typeof (startpos) == "undefined") {
        startpos = string.length;
    } else if(startpos < 0) {
        startpos = 0;
    }
    var stringToWorkWith = string.substring(0, startpos + 1);
    var lastIndexOf = -1;
    var nextStop = 0;
    var result;
    while((result = regex.exec(stringToWorkWith)) != null) {
        lastIndexOf = result.index;
        regex.lastIndex = ++nextStop;
    }
    return lastIndexOf;
}

/**
 * =========================== downloadBase64 =======================
 * @param {*} url
 * @param {*} body
 * @param {*} fileType
 * @returns
 */
export const downloadBase64 = async (
    url,
    body,
    fileType,
) => {
    try {
        const header = {
            headers: {"Authorization": `Bearer ${localStorage.getItem("token")}`},
        };
        const axios_URL = `${url}`;
        const { data } = await axios.post(axios_URL,body, {
            ...header,
            responseType: "blob",
        });
        if (data) {
            const file = new Blob([data], {
                type: fileType,
            });
            return await blobToBase64(file);
        }
    } catch (error) {
        return null;
    }
};
export const downloadBase64Get = async (
    url,
    fileType,
) => {
    try {

        const axios_URL = `${url}`;
        const { data } = await axios.get(axios_URL, {
            responseType: "blob",
        });
        if (data) {
            const file = new Blob([data], {
                type: fileType,
            });
            return await blobToBase64(file);
        }
    } catch (error) {
        return null;
    }
};
export const downloadAndSave = async (
    url,
    body,
    fileType,
) => {
    try {
        const header = {
            headers: {"Authorization": `Bearer ${localStorage.getItem("token")}`},
        };
        const axios_URL = `${url}`;
        const { data } = await axios.post(axios_URL,body, {
            ...header,
            responseType: "blob",
        });
        if (data) {
            const file = new Blob([data], {
                type: fileType,
            });
            saveAs(file, "file.pdf");
        }
    } catch (error) {
        return null;
    }
};


export const giftConditions = [
    {
        label: "Good Condition",
        value: "Good Condition"
    },
    {
        label: "Damaged",
        value: "Damaged"
    },
    {
        label: "Used",
        value: "Used"
    },
    {
        label: "Exited",
        value: "Exited"
    },
];

export const inArray = (haystack, needle) => {
    if (!haystack) {
        return false;
    }

    return haystack.filter(v => v === needle).length > 0;
}
export const commonError = "Unexpected Error or Server Error, Contact Admin !!";


export const getFileMime = (name) => {
    let lowerCase = name?.split(".")?.pop()?.toLowerCase();
    return isImageExt(lowerCase) ? `image/${lowerCase}` :
        inArray(['pdf'], lowerCase) ? 'application/pdf' :
            inArray(['mov', 'mp4', 'mkv', 'wmv', 'avi', 'webm'], lowerCase) ? `video/${lowerCase}` :
                'application/octet-stream';
}

export const getError = (resp) => {
    return resp?.data?.data?.message ?? resp?.data?.message ?? resp?.response?.data?.message ?? commonError;
}
export const getSuccessMessage = (resp) => {
    return resp?.data?.message ?? "Success !";
}

export const isImageExt = (name) => {
    return inArray(['jpg', 'png', 'webp', 'jpeg', 'gif'], name);
}


export const allowedTypes = ".pdf,image/*,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";


export const getDuration = (end, date = null) => {
    if (end) {
        let years = moment(end).diff(date ?? moment(), "years");
        if (years > 0) {
            return `${years} yrs`;
        }
        let months = moment(end).diff(date ?? moment(), "months");
        if (months > 0) {
            return `${months} mo`;
        }
        let days = moment(end).diff(date ?? moment(), "days");

        if (days > 0) {
            return `${days} dys`;
        }
        let hours = moment(end).diff(date ?? moment(), "hours");

        if (hours > 0) {
            return `${hours} hrs`;
        }

        let minutes = moment(end).diff(date ?? moment(), "minutes");

        if (minutes < 0) {
            return `-${getDuration(new Date(), end)}`;
        }

        return `${minutes} min`;


    } else {
        return "Un-estimated";
    }
};

export const requestInterceptor = (request) => {
    const token = localStorage.getItem("token");
    if (request.headers && request.headers['access-token'] !== token && token) {
        request.headers['access-token'] = token;
    }
    return request;
}

export const responseInterceptor = (response) => {
    localStorage.setItem("lastSeen", new Date().toString());
    return response
}
