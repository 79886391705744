import {Link, Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import {connect} from "react-redux";
import {mapStateToProps} from "../store/helpers/mapState";
import {mapDispatchToProps} from "../store/helpers/mapDispatch";
import {useTranslation} from "react-i18next";
import {Card, Col, Row} from "react-bootstrap";
import SimpleLawHeader from "../components/SimpleLawHeader";
import DocumentItem from "../components/DocumentItem";
import NoResultsFound from "../components/NoResultsFound";
import LoadingSpinner from "../components/LoadingSpinner";
import {useEffect, useRef, useState} from "react";
import {getQuery} from "../store/helpers/common_functions";

function LawsLayout({sections,documents,loadingDocs,FetchDetails,ResetDocuments}) {
    const location = useLocation();
    const {i18n,t} = useTranslation();
    const {id} = useParams();
    const navigate = useNavigate();

    const divRef = useRef(null);

    const [active,setActive] = useState();
    const [activeChild,setActiveChild] = useState();
    const [counts, setCounts] = useState({});
    const activeChildQuery = getQuery('child');
    const activeGChildQuery = getQuery('g-child');

    const loadSectionCounts = async () => {
      const resp = await FetchDetails(`dropdowns/sections?language=${i18n.language}`);
      if(resp.status){
          setCounts(
              resp.data.reduce((prev,current)=>({...prev,[current.value]:current.count}),{})
          )
      }
    }

    useEffect(()=>{
        loadSectionCounts().then();
    },[i18n.language]);


    const scrollToDiv = () => {
        divRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        if(loadingDocs){
            scrollToDiv();
        }
    }, [loadingDocs]);


    useEffect(()=>{
        ResetDocuments();
    },[activeChildQuery,activeGChildQuery,id]);
    useEffect(()=>{
        if(id && sections){
            const active = sections?.find(v=>v.id === id);
            setActive(active);

            if(!activeChildQuery && active.children?.length){
                const child0 = active.children[0];
                console.log("child 0",child0)
                if(child0) {
                    if(child0.children?.length){
                        navigate(`?child=${child0.id}&g-child=${child0.children[0].id}`);
                    }else {
                        navigate(`?child=${child0.id}`);
                    }
                }
            }else if(active?.children && activeChildQuery){
                const activeChild = active.children.find(v=>v.id === activeChildQuery);
                if(!activeGChildQuery && activeChild?.children?.length){
                    navigate(`?child=${activeChild.id}&g-child=${activeChild.children[0].id}`);
                }
                setActiveChild(activeChild);

            }
        }
    },[id,activeChildQuery,sections]);

    let total1 = 0;
    let total2 = 0;

    return <div className={'shadow my-3'}>
        <div className={'d-flex p-3 rounded-top-3 justify-content-center'} style={{backgroundColor:"#20603D"}}>
            {
                sections.map((v)=>{
                    let label,icon,href,counter = 0;
                    switch (v.id) {
                        case "1":
                            icon = require('./../assets/balance_icon.png');
                            href = `in-force`;
                            counter = counts['1.1'] + counts['1.2'];
                            break;
                        case "2":
                            href = "judgement";
                            icon = require('./../assets/law_icon.png');
                            counter = counts['2.1'] + counts['2.2.1'] + counts['2.2.2'] + counts['2.2.3'];
                            break;
                        default:
                            icon = "";
                            break;
                    }
                    switch (i18n.language){
                        case "en":
                            label = v['name_en'];
                            break;
                        case "fr":
                            label = v['name_fr'];
                            break;
                        case "rw":
                            label = v['name_rw'];
                            break;
                        default:
                            label = "";
                    }
                    return id === v.id && <Link to={`${href}/${v.id}`} className={`nav-custom-pill text-decoration-none rounded p-1 px-3 ${id === v.id ? 'active-' : ''}`} style={{fontSize:"25px",color:"white"}}>
                        <img src={icon} alt=""/>
                        <span className={'ms-2'}>{label}</span>
                        {counter>0 && <span className={'badge bg-success ms-2'}>{counter}</span>}
                    </Link>
                })
            }
        </div>

            <div className={'card-body p-3'}>
                <div>
                    <div className={'d-flex mb-3'}>
                        {
                            active?.children?.map((v)=>{
                                let label,icon,href="",counter = 0;
                                switch (v.id) {
                                    case "1.1":
                                        counter = counts['1.1'];
                                        break;
                                    case "1.2":
                                        counter = counts['1.2'];
                                        break;
                                    case "2.1":
                                        icon = "./../assets/balance_icon.png";
                                        href = `/laws/judgement/${active?.id}`;
                                        counter = counts['2.1'];
                                        break;
                                    case "2.2":
                                        icon = "./../assets/law_icon.png";
                                        href = `/laws/report/${active?.id}`;
                                        counter = counts['2.2.1'] + counts['2.2.2'] + counts['2.2.3'];
                                        break;
                                    default:
                                        icon = "";
                                        href = "";
                                        break;
                                }
                                switch (i18n.language){
                                    case "en":
                                        label = v['name_en'];
                                        break;
                                    case "fr":
                                        label = v['name_fr'];
                                        break;
                                    case "rw":
                                        label = v['name_rw'];
                                        break;
                                    default:
                                        label = "";
                                }

                                total1 += counter;
                                return <Link to={`${href}?child=${v.id}${v.children?.length ? `&g-child=${v.children[0].id}` : ''}`} className={`nav-custom-pill text-decoration-none rounded shadow-sm me-2 p-1 px-3 ${activeChildQuery === v.id ? 'active' : ''}`} style={{fontSize:"15px",color:"#3A4F39"}}>
                                    <span className={'ms-2'}>{label}</span>
                                    {counter>0 && <span className={'badge bg-success ms-2'}>{counter}</span>}
                                </Link>
                            })
                        }
                       <span className={`nav-custom-pill text-decoration-none rounded shadow-sm me-2 p-1 px-3 fw-bold`} style={{fontSize:"15px",color:"#3A4F39"}}>
                        <span className={'ms-2'}>{t('all')}</span>
                        {<span className={'badge bg-success ms-2'}>{total1}</span>}
                    </span>
                    </div>
                </div>
                {activeChild?.children?.length>0 && <div>
                    <div className={'d-flex mb-3'}>
                        {
                            activeChild?.children?.map((v,index)=>{
                                let label,icon,href,counter = 0;
                                switch (index) {
                                    case 0:
                                        icon = "bi bi-building";
                                        href = "/laws/report";
                                        counter = counts["2.2.1"];
                                        break;
                                    case 1:
                                        icon = "bi bi-diagram-2";
                                        href = "/laws/precedence";
                                        counter = counts["2.2.2"];
                                        break;
                                    case 2:
                                        icon = "bi bi-book";
                                        href = "/laws/scholar";
                                        counter = counts["2.2.3"];
                                        break;
                                    default:
                                        icon = "";
                                        break;
                                }
                                switch (i18n.language){
                                    case "en":
                                        label = v['name_en'];
                                        break;
                                    case "fr":
                                        label = v['name_fr'];
                                        break;
                                    case "rw":
                                        label = v['name_rw'];
                                        break;
                                    default:
                                        label = "";
                                }
                                total2 += counter;
                                return <Link to={`${href}/${id}?child=${activeChild?.id}&g-child=${v.id}`} className={`nav-custom-pill text-decoration-none shadow-sm rounded p-1 px-3 me-2 ${activeGChildQuery === v.id ? 'active' : ''}`} style={{fontSize:"15px",color:"#3A4F39"}}>
                                    <i className={icon}></i>
                                    <span className={'ms-2'}>{label}</span>
                                    {counter>0 && <span className={'badge bg-success ms-2'}>{counter}</span>}
                                </Link>
                            })
                        }
                        <span className={`nav-custom-pill text-decoration-none rounded shadow-sm me-2 p-1 px-3 fw-bold`} style={{fontSize:"15px",color:"#3A4F39"}}>
                            <span className={'ms-2'}>{t('all')}</span>
                            {<span className={'badge bg-success ms-2'}>{total2}</span>}
                        </span>
                    </div>
                </div>}
                <div>
                    <SimpleLawHeader title={location.pathname.indexOf('in-force') ? t('taxonomy') : (location.pathname.indexOf('report') ? t('taxonomy') : t('taxonomy'))}/>
                </div>
                <Row>
                    <Col md={4}>
                        <Outlet/>
                    </Col>
                    <Col md={8}>
                        <div ref={divRef}>
                            {loadingDocs ? <LoadingSpinner/> : !documents ? <div></div> : !documents.length ? <NoResultsFound/> : documents.map(item=><DocumentItem item={item}/>)}
                        </div>
                     </Col>
                </Row>
            </div>

    </div>
}

export default connect(mapStateToProps,mapDispatchToProps)(LawsLayout);
