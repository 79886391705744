import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";

const LegalNotices = () => {
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(true);
    const {t} = useTranslation();
    return <div style={{marginTop:"100px"}}>
        <Helmet>
            <title>Legal Notices</title>
            <meta name="description" content="Rwanda Law Reform Commission (RLRC) governed by the Law no 023/2024 of 25/01/2024 has the mission of analyzing laws in force and providing technical advice on draft bills with regard to both substance and drafting." />
            <link rel="canonical" href="https://www.amategeko.gov.rw/legal" />
        </Helmet>
        <div className={'shadow-sm m-3 p-3 rounded text-start'}>
            <div className={'d-flex'}>
                <h4 className={'flex-grow-1 raleway-2'}>{t('public_notices_title')}</h4>
                <a href={'#'} style={{color:"#20603D"}} onClick={()=>{
                    setShow4(v=>!v);
                }}>
                    <i className={`bi bi-caret-${show4 ? 'down' : 'left'}-fill`} style={{fontSize:'30px'}}></i>
                </a>
            </div>
            {show4 && <p style={{textAlign:"justify"}} dangerouslySetInnerHTML={{__html:t('public_notices')}}>
            </p>}
        </div>
      <div className={'shadow-sm m-3 p-3 rounded text-start'}>
          <div className={'d-flex'}>
              <h4 className={'flex-grow-1 raleway-2'}>{t('legal_notices')}</h4>
              <a href={'#'} style={{color:"#20603D"}} onClick={()=>{
                  setShow1(v=>!v);
              }}>
                  <i className={`bi bi-caret-${show1 ? 'down' : 'left'}-fill`} style={{fontSize:'30px'}}></i>
              </a>
          </div>
          {show1 && <p style={{textAlign:"justify"}} dangerouslySetInnerHTML={{__html:t('legal_content')}}>
          </p>}
      </div>

      <div className={'shadow-sm m-3 p-3 rounded text-start'}>
          <div className={'d-flex'}>
              <h4 className={'flex-grow-1 raleway-2'}>{t('privacy_policy')}</h4>
              <a href={'#'} style={{color:"#20603D"}} onClick={()=>{
                  setShow2(v=>!v);
              }}>
                  <i className={`bi bi-caret-${show2 ? 'down' : 'left'}-fill`} style={{fontSize:'30px'}}></i>
              </a>
          </div>
          {show2 && <p style={{textAlign:"justify"}} dangerouslySetInnerHTML={{__html:t('privacy_content')}}>
          </p>}
      </div>

      <div className={'shadow-sm m-3 p-3 rounded text-start'}>
          <div className={'d-flex'}>
              <h4 className={'flex-grow-1 raleway-2'}>{t('service_terms')}</h4>
              <a href={'#'} style={{color:"#20603D"}} onClick={()=>{
                  setShow3(v=>!v);
              }}>
                  <i className={`bi bi-caret-${show3 ? 'down' : 'left'}-fill`} style={{fontSize:'30px'}}></i>
              </a>
          </div>
          {show3 && <p style={{textAlign:"justify"}} dangerouslySetInnerHTML={{__html:t('terms_content')}}>
          </p>}
      </div>


  </div>
}

export default LegalNotices;