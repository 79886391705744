import LawComponent from "../components/LawComponent";
import {precedenceFlow} from "../constants";
import {useSearchParams} from "react-router-dom";

function LawsPrecedence() {

    const [searchParams] = useSearchParams();
    const activeChildQuery = searchParams.get('child');
    const activeGChildQuery = searchParams.get('g-child');

    const id = activeGChildQuery ?? activeChildQuery;
    return <div>
        <LawComponent flow={precedenceFlow} topValue={{
            value:id,
            key:'section'
        }} id={id} track={{value:id,label:'section',key:'section'}}/>
    </div>
}

export default LawsPrecedence;
