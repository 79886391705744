import {
    USER_INFO, ERROR, SUCCESS, GET_SECTIONS, GET_DOCUMENTS, SET_LOADING
} from "./type";
import sections from '../../json/sections.json';

const initialState = {
    loggedUser:null,
    errorMessage: null,
    successMessage: null,
    sections,
    documents:null,
    loadingDocs:false,
};

export const userReducer = (
    state = initialState,
    { type, payload }
) => {
    switch (type) {
        case USER_INFO:
            return  { ...state, loggedUser: payload };
        case ERROR:
            return  { ...state, errorMessage: payload };
        case SUCCESS:
            return  { ...state, successMessage: payload };
        case GET_SECTIONS:
            return  { ...state, sections: payload };
        case GET_DOCUMENTS:
            return  { ...state, documents: payload };
        case SET_LOADING:
            return  { ...state, loadingDocs: payload };
        default:
            return state;
    }
};
