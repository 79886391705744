import LawComponent from "../components/LawComponent";
import {flow} from "../constants";
import {useSearchParams} from "react-router-dom";

function LawsReports() {

    const [searchParams] = useSearchParams();
    const activeChildQuery = searchParams.get('child');
    const activeGChildQuery = searchParams.get('g-child');

    const id = activeGChildQuery ?? activeChildQuery;
    return <div>
        <LawComponent flow={flow} topValue={{
            value:id,
            key:'section'
        }} id={id} track={{value:id,label:'section',key:'section'}}/>
    </div>
}

export default LawsReports;
